import actionTypes from "../redux/action.types";
import * as Actions from "../redux/actions/user.actions";
import ApiClient from "../api/ApiClient";
import EndPoint from "../api/Endpoints";
import axios from "axios";
import decode from "jwt-decode";
import {
  FetchFiles,
  createFileFolder,
  uploadFile,
  deleteFile,
} from "../services/process.service";
import Lngs from "../redux/reducers/languages.js";
import { columnSelectionComplete } from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
//import * as yup from "yup";
//import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_API_CONFIG;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_CONFIG,
  // timeout: 1000,
});

var defLang = process.env.REACT_APP_DEF_LANGUAGE;

export function createValidationSchema(fields) {
  /*
	  const ObjectSchema = fields.reduce((schema, field) => {
		if (field?.validations?.length) {
			if (field.validations.length>0) {
			  schema[field.name] = field.validations.reduce(
				(yup, type) => {
				  if (field.params[type]) {
					const params = Array.isArray(field.params[type])
					  ? field.params[type]
					  : [field.params[type]];
	
					yup = yup[type](...params);
				  } else {
					yup = yup[type]();
				  }
		 
				  return yup;
				},
				{ ...yup }
			  );
				}else{ console.log("nova la val")}
		}
	
		return schema;
	  }, {});
	
	  return yup.object().shape({ ...ObjectSchema });*/
}

const parse_res = (api) => api.then((data) => data.data);

export async function ExportToExcel(formData, _formValues, fileName) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let excludedTypes = [
    "hidden",
    "password",
    "image",
    "staticimage",
    "multipleimages",
    "space",
    "orderitems",
    "iframe",
    "button",
    "scriptarea",
  ];

  let formValues = [[]];

  let headerInfo = [];
  formData.map(function (key, item) {
    if (excludedTypes.indexOf(key.type) < 0) {
      headerInfo.push(key.label);
      formValues[0].push(_formValues[key.name]);
    }
  });

  console.log("ExportToExcel", formValues, fileName);

  const ws = XLSX.utils.json_to_sheet(formValues);
  /* custom headers */
  XLSX.utils.sheet_add_aoa(ws, [headerInfo], { origin: "A1" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export async function callProtectedApi(api2, formData) {
  var resp;
  var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
  await axios({
    url: BASE_URL + api2,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  })
    .then((data) => {
      resp = data;
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false };
      return resp;
    });
}

export function find(_json, _value, _key, _childSearch) {
  var pos = -1;
  _json.map(function (key, item) {
    if (key[_key] == _value) {
      pos = item;
    }
    if (_childSearch) {
      key[_childSearch].map(function (key2, item2) {
        if (key2[_key] == _value) {
          pos = [item, item2];
        }
      });
    }
  });
  return pos;
}

export function findValueById(_json, _value, _key, _param) {
  var pos = -1;
  var resp;
  _json.map(function (key, item) {
    if (key[_key] == _value) {
      pos = item;
    }
  });
  if (pos >= 0) {
    resp = _json[pos][_param];
  } else {
    resp = "NOT FOUND";
  }

  return resp;
}

export function findObjName(_event) {
  var continueSerching = true;
  var objName = "";
  var event = _event.target;
  while (continueSerching) {
    if (event.name) {
      continueSerching = false;
      objName = event.name ? event.name : event.attributes.name.nodeValue;
    } else {
      event = event.parentNode;
    }
  }
  return objName;
}

export function getUserName() {
  return getCookie(process.env.REACT_APP_NAME + "_username");
}

export function getToken() {
  return getCookie(process.env.REACT_APP_NAME + "_usertoken");
}

export function getUserRol() {
  var role;
  try {
    if (getToken() != undefined) {
      if (typeof getToken() != undefined) {
        let profile = decode(getToken(), process.env.REACT_APP_SECRET_KEY);
        role = profile.result.USERGROUPID;
      }
    }
  } catch (exc) {
    //console.log("Exc >>>>>")
  }
  return role;
}

export function getUserInfo() {
  var info = {};
  try {
    if (getToken() != undefined) {
      if (typeof getToken() != undefined) {
        let profile = decode(getToken(), process.env.REACT_APP_SECRET_KEY);
        info = profile.result;
      }
    }
  } catch (exc) {
    console.log("Exc >>>>>" + exc);
  }
  return info;
}

export function parseDate(f) {
  var fecha = "";
  try {
    var yy = f.getFullYear();
    var mm =
      f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1;
    var dd = f.getDate() < 10 ? "0" + f.getDate() : f.getDate();
    var hh = f.getHours() < 10 ? "0" + f.getHours() : f.getHours();
    var mi = f.getMinutes() < 10 ? "0" + f.getMinutes() : f.getMinutes();
    var se = f.getSeconds() < 10 ? "0" + f.getSeconds() : f.getSeconds();

    var fecha = yy + "-" + mm + "-" + dd + " " + hh + ":" + mi + ":" + se;
  } catch (Excep) {
    fecha = "";
  } finally {
    return fecha;
  }
}

export function setCookie(name, value, hours) {
  try {
    var expires;
    if (hours) {
      var date = new Date();
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
  } catch (err) {
    console.log(">>>>>>> ERR SETTING COOKIE", err);
  }
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  var resp;
  //console.log("COOOOOKIE::::::::::::::::::",ca)
  for (let i = 0; i < ca.length; i++) {
    let _c = ca[i].trim();
    let c = _c.split("=");
    let val = c[0] + "=";

    if (val.indexOf(nameEQ) == 0) {
      return _c.substring(nameEQ.length, _c.length);
    }
    /*while (c.charAt(0) == ' ') {
			c = c.substring(1, c.length);
			
			console.log("CHECK WITH:",c )
			
			if (c.indexOf(nameEQ) == 0) {
				console.log("ENCONTRADO", c)
				return c.substring(nameEQ.length, c.length);
			}
			}
			*/
  }
  return resp;
}

export async function manageFile(event, _objFile, _files) {
  var objFile;

  try {
    var action, data;

    if (event.target) {
      var _data = findObjName(event);
      var data = _data.split("_");
      action = data[0];
    } else {
      var data = event.split("_");
      action = data[0];
    }
    //console.log(data, action)

    _objFile["selected"] = [];
    _objFile["selectedName"] = null;
    _objFile["selectedType"] = null;
    if (action === "selectItem") {
      let item = _objFile.items[data[1]];
      if (item.type == "folder") {
        objFile = await listFiles(item.name, _objFile.objectName, []);
        objFile["route"] = item.name;
      } else {
        objFile = _objFile;
        objFile["selected"] = item.path;
        objFile["selectedName"] = item.label;
        _objFile["selectedType"] = item.type;
      }
    } else if (action === "getParent") {
      //objFile = await listFiles ("",_objFile.objectName, _objFile.selected )
      //objFile["route"] = ""
      var routes = _objFile.route.split("/");
      console.log(routes);
      let newRoute = "";
      for (let i = 0; i < routes.length - 2; i++) {
        newRoute = newRoute + routes[i] + "/";
      }

      if (routes.length > 1) {
        objFile = await listFiles(
          newRoute,
          _objFile.objectName,
          _objFile.selected
        );
      } else {
        objFile = _objFile;
      }
    } else if (action === "refresh") {
      var ContinuationToken;
      if (_objFile.ContinuationToken) {
        if (_objFile.ContinuationToken != "") {
          ContinuationToken = _objFile.ContinuationToken;
        }
      }
      objFile = await listFiles(
        _objFile.route,
        _objFile.objectName,
        _objFile.selected,
        ContinuationToken
      );
    } else if (action === "showFolderName") {
      objFile = _objFile;
      objFile.showFolderName = objFile.showFolderName ? false : true;
      objFile.showUploadFile = false;
      objFile.newFolderValue = "";
    } else if (action === "showUploadFile") {
      objFile = _objFile;
      objFile.showUploadFile = objFile.showUploadFile ? false : true;
      objFile.showFolderName = false;
    } else if (action === "newFolderValue") {
      objFile = _objFile;
      const pattern = new RegExp("^[0-9A-Z_-]+$", "i");
      if (pattern.test(event.target.value)) {
        objFile.newFolderValue = event.target.value;
      }
    } else if (action === "createFolder") {
      let folderName = "";
      if (_objFile.route === "") {
        folderName = _objFile.newFolderValue;
      } else {
        folderName = _objFile.route + _objFile.newFolderValue;
      }
      var createFolder = await createFileFolder({
        foldername: folderName + "/",
      });
      objFile = await listFiles(
        _objFile.route,
        _objFile.objectName,
        _objFile.selected
      );
      objFile.showFolderName = false;
    } else if (action === "checkItem") {
      var objFile = _objFile;

      if (objFile.originalValues[data[1]].checked) {
        let pos = _objFile.selected.indexOf(
          objFile.originalValues[data[1]].name
        );
        _objFile.selected.splice(pos, 1);
      } else {
        _objFile.selected.push(objFile.originalValues[data[1]].path);
      }
      //console.log(_objFile.selected)

      objFile.originalValues[data[1]].checked = objFile.originalValues[data[1]]
        .checked
        ? false
        : true;

      /*
			  }else if (action==="inputNewFile"){
				var objFile = _objFile
				objFile.inputNewFile= event.target.files[0]*/
    } else if (action === "uploadFile") {
      for (let f in _files) {
        //console.log(_files[f])
        const formData = new FormData();
        formData.append("file", _files[f], _files[f].name);

        if (_objFile.route) {
          if (_objFile.route != "") {
            formData.append("foldername", _objFile.route);
          }
        }
        let respU = await uploadFile(formData, _files[f].name);
        //console.log(respU)
      }

      objFile = await listFiles(
        _objFile.route,
        _objFile.objectName,
        _objFile.selected
      );
    } else if (action === "deleteItems") {
      var folder = "";

      if (_objFile.route) {
        _objFile["route"] = _objFile.route;
        folder = _objFile.route;
      }
      console.log(_objFile.items);
      var isValid = false;
      for (let i in _objFile.items) {
        if (_objFile.items[i].checked) {
          isValid = true;
          let resp = await deleteFile({
            archivo_id: _objFile.items[i].archivo_id,
            path: _objFile.items[i].path,
          });
        }
      }
      if (isValid) {
        objFile = await listFiles(
          folder,
          _objFile.objectName,
          _objFile.selected
        );
        //toast.error("Item(s) eliminados");
      } else {
        objFile = _objFile;
        //toast.error("No items");
      }
    } else if (action === "selectMultiple") {
      /*	var objSelected= []
				for (let i in _objFile.items ){
						if(_objFile.items[i].checked){
							objSelected.push(_objFile.items[i].name)
						}
					}	
					objFile = _objFile
					objFile["selected"] = objSelected*/
      //console.log(	_objFile["selected"])
      objFile = _objFile;
    } else if (action === "manageInput") {
      objFile = _objFile;
      objFile["objectName"] = data[1];
      objFile["selected"] = [event.target.value];
    } else if (action === "ContinuationToken") {
      var PreviousToken;
      var currHistory = _objFile.history;
      if (currHistory) {
        if (currHistory.length) {
          let pos = find(currHistory, _objFile.ContinuationToken, "nextToken");
          if (pos >= 0) {
            PreviousToken = currHistory[pos].prevToken;
          }
        }
      }
      objFile = await listFiles(
        _objFile.route,
        _objFile.objectName,
        _objFile.selected,
        PreviousToken
      );
      objFile["history"] = currHistory;
    } else if (action === "NextContinuationToken") {
      let history = _objFile.history ? _objFile.history : [];
      if (history.length == 0) {
        history.push({
          prevToken: "",
          nextToken: _objFile.NextContinuationToken,
        });
      }
      objFile = await listFiles(
        _objFile.route,
        _objFile.objectName,
        _objFile.selected,
        _objFile.NextContinuationToken
      );
      var isValid = true;
      for (let h in history) {
        if (
          history[h].prevToken == objFile.ContinuationToken &&
          history[h].nextToken == objFile.NextContinuationToken
        ) {
          isValid = false;
        }
      }
      if (isValid) {
        history.push({
          prevToken: objFile.ContinuationToken,
          nextToken: objFile.NextContinuationToken,
        });
      }
      objFile["history"] = history;
    } else if (action === "filterValue") {
      objFile = _objFile;
      objFile["filterValue"] = event.target.value.toUpperCase();
      if (!objFile["originalValues"]) {
        objFile["originalValues"] = objFile["items"];
      }

      if (event.target.value.length >= 3) {
        let searchValue = (event.target.value + "").toUpperCase();
        let newList = [];
        for (let i in objFile["originalValues"]) {
          let item = objFile["originalValues"][i].label;
          if (item.toUpperCase().indexOf(searchValue + "") >= 0) {
            newList.push(objFile["originalValues"][i]);
          }
        }
        objFile["items"] = newList;
      } else {
        objFile["items"] = objFile["originalValues"];
      }
    } else {
      console.log(">>>>>>>>", "No valid option", action);
      objFile = _objFile;
    }
  } catch (Excep) {
    console.log(">>>>>>>>", Excep);
    return false;
  } finally {
    return objFile;
  }
}

export async function listFiles(
  _foldername,
  _objectName,
  _selected,
  _ContinuationToken
) {
  //console.log(_foldername)
  var items = [];
  var item = 0;

  //var resp = await  FetchFiles( { foldername: _foldername } );
  //console.log("_foldername",_foldername)
  /*
	if (_foldername==""){
		 
		for (let f in resp.data ){
			let data = resp.data[f].route.split("/")
			let folder =  (data[ data.length-2 ])			 	
			let pos = find (items, folder, "name")
			if  (pos<0){
				items.push ({ item:item,  name : folder, label: folder, type:"folder" } )	
				item++	
			}			
		}
		items.sort(function (a, b){
			return ((b.name < a.name) ? -1 : ((b.name > a.name) ? 1 : 0));
		}) 
	}else{		
		for (let f in resp.data ){
			let data = resp.data[f].route.split("/")
			let folder =  (data[ data.length-2 ])
			 
			if (_foldername == folder){		
				let file =  (data[ data.length-1 ])
				let type = "file"
				if (resp.data[f].file_type.indexOf ( "image")>=0 ){
					type= "image"
				}
				items.push ({ item:item,  name : file, label: resp.data[f].filename, path:resp.data[f].route , type:type, archivo_id:resp.data[f].id } )	
				item++	
			}
		}
		items.sort(function (a, b){
			return ((b.name < a.name) ? -1 : ((b.name > a.name) ? 1 : 0));
		})		
	}
	*/

  var userInfo = getUserInfo();
  var forceCreate = false;
  var storepath;
  if (userInfo.STORECODE) {
    storepath = "store_" + userInfo.STORECODE.toUpperCase() + "/";
  }
  if (_foldername == storepath) {
    forceCreate = true;
  }

  var resp = await FetchFiles({
    foldername: _foldername,
    MaxKeys: 180,
    forceCreate: forceCreate,
  });
  for (let f in resp.folders) {
    if (resp.folders[f] != "/") {
      let label =
        resp.folders[f].split("/")[resp.folders[f].split("/").length - 2];
      items.push({
        item: item,
        name: resp.folders[f],
        label: label,
        type: "folder",
      });
      item++;
    }
  }
  for (let f in resp.files) {
    let label =
      resp.files[f].filename.split("/")[
        resp.files[f].filename.split("/").length - 1
      ];
    let isChecked = false;
    if (_selected) {
      if (_selected.indexOf(resp.files[f].filepath) >= 0) {
        isChecked = true;
      }
    }
    items.push({
      item: item,
      name: resp.files[f].filename,
      type: "file",
      label: label,
      checked: isChecked,
      path: resp.files[f].filepath,
    });
    item++;
  }
  items.sort(function (a, b) {
    return b.name.toUpperCase() > a.name.toUpperCase()
      ? -1
      : b.name.toUpperCase() < a.name.toUpperCase()
      ? 1
      : 0;
  });

  if (items.length == 0 && forceCreate) {
    //console.log("creating store folder")
    var createFolder = await createFileFolder({ foldername: _foldername });
  }

  let objFile = {
    items: items,
    originalValues: items,
    selected: _selected ? _selected : [],
    newFolderValue: "",
    route: _foldername,
    inputNewFile: null,
    filterValue: "",
    objectName: _objectName,
    ContinuationToken: resp.ContinuationToken,
    NextContinuationToken: resp.NextContinuationToken,
  };
  return objFile;
}

export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export async function preloadForm(formData, postloadscript) {
  try {
    var appUrl = process.env.REACT_APP_URL_CONFIG;
    for (let key in formData) {
      let field = formData[key];
      if (field.type == "date") {
        field.value = new Date();
      }
      if (field.type == "daterange") {
        let startValue = new Date(new Date().setDate(7));
        let endValue = new Date();
        field.value = [startValue, endValue];
      }

      if (field.getDataDB) {
        var values = await ApiClient.getCustomData(
          field.getDataDB,
          field.apidata
        );
        if (values.length > 0) {
          var fields = Object.keys(values[0]);
          values.map(function (key, item) {
            key["value"] = key[fields[0]];
            key["text"] = key[fields[1]];
          });
        }
        field["values"] = values;
        field["originalvalues"] = values;
      }

      if (field.apiurl) {
        var apidata = {};
        var _apidata = field.apidefdata.split(",");
        for (let d in _apidata) {
          let info = _apidata[d].split("=");
          apidata[info[0]] = info[1];
        }
        var values = await ApiClient.getCustomData(field.apiurl, apidata);
        if (values.length > 0) {
          var fields = Object.keys(values[0]);
          values.map(function (key, item) {
            key["value"] = key[fields[0]];
            key["text"] = key[fields[1]];
          });
        }
        field["values"] = values;
        field["originalvalues"] = values;
      }
      if (postloadscript) {
        if ((postloadscript + "").length > 0) {
          try {
            const { simpleGetData } = require("./Functions");
            const { simplePostData } = require("./Functions");
            const utils = require("./Utils");
            await eval(postloadscript);
          } catch (Exc) {
            console.log("EXC:", Exc);
            //toast.error("ERROR"+Exc);
          }
        }
      }
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
  } finally {
    return formData;
  }
}

//createForm
export async function parseFormInfo(formInfo) {
  try {
    var formData = [];
    var sizeWeb = formInfo.data.length;
    var maxSize = 0;
    let isArray = true;
    for (let i in formInfo.data) {
      if (Array.isArray(formInfo.data[i])) {
        let tmpSize = formInfo.data[i].length;
        if (tmpSize > maxSize) {
          maxSize = tmpSize;
        }
      } else {
        isArray = false;
      }
    }
    if (!isArray) {
      let fixedData = formInfo.data;
      maxSize = formInfo.data.length;
      formInfo.data = [fixedData];
    }

    for (let j = 0; j < maxSize; j++) {
      for (let i in formInfo.data) {
        var xs = 12;
        if (sizeWeb <= 2) {
          xs = 12 / sizeWeb;
        } else {
          xs = 6;
        }
        var lg = 12 / sizeWeb;

        if (formInfo.data[i][j]) {
          if (formInfo.data[i][j].size) {
            if (formInfo.data[i][j].size.xs != "auto") {
              xs = formInfo.data[i][j].size.xs;
            }
            if (formInfo.data[i][j].size.lg != "auto") {
              lg = formInfo.data[i][j].size.lg;
            }
          } else {
            xs = 12;
            lg = formInfo.data[i][j].layout_sizeX;
          }
          var values = [];
          var value = formInfo.data[i][j].value
            ? formInfo.data[i][j].value
            : "";
          try {
            values =
              formInfo.data[i][j].values === "[object Object]"
                ? ""
                : formInfo.data[i][j].values;

            let type = formInfo.data[i][j].type;
            if (
              (type === "select" ||
                type === "options" ||
                type === "optionsGrid" ||
                type === "optionsChart" ||
                type === "radio" ||
                type === "autocomplete") &&
              values.options
            ) {
              if (values.options.length >= 1) {
                value = values.options[0].value;
              }
            }
            if (type === "multiple") {
              value = [];
            }
            if (
              formInfo.data[i][j].apiurl &&
              formInfo.data[i][j].apiurl != ""
            ) {
              //console.log("url", formInfo.data[i][j].apiurl)
              var apidata = {};
              var _apidata = formInfo.data[i][j].apidefdata.split(",");
              for (let d in _apidata) {
                let info = _apidata[d].split("=");
                apidata[info[0]] = info[1];
              }

              values = await ApiClient.getCustomData(
                formInfo.data[i][j].apiurl,
                apidata
              );
              if (values.length > 0) {
                var fields = Object.keys(values[0]);
                values.map(function (key, item) {
                  key["value"] = key[fields[0]];
                  key["text"] = key[fields[1]];
                });
                if (formInfo.data[i][j].type.indexOf("autocomplete") < 0) {
                  value = values[0][fields[0]];
                }
              }
            }
          } catch (Exc) {
            console.log(">>> Exc getting data", formInfo.data[i][j]);
          }
          var validations = [];
          var params = {};

          var _validations = formInfo.data[i][j].validations;
          if (_validations) {
            Object.entries(_validations).map(function (key, item) {
              if (_validations[key[0]].status) {
                if (key[0] !== "required") {
                  if (key[0] == "email") {
                    validations.push("string");
                    validations.push(key[0]);
                  } else {
                    validations.push(key[0]);
                  }
                }
                if (key[0] === "min") {
                  params[key[0]] = [
                    _validations[key[0]].min,
                    _validations[key[0]].message,
                  ];
                } else if (key[0] === "max") {
                  params[key[0]] = [
                    _validations[key[0]].max,
                    _validations[key[0]].message,
                  ];
                } else {
                  params[key[0]] = _validations[key[0]].message;
                }
              }
            });
          }

          let finalField = {
            name: formInfo.data[i][j].name,
            label: formInfo.data[i][j].label,
            placeholder: formInfo.data[i][j].label,
            type: formInfo.data[i][j].type,
            size: {
              xs: xs,
              lg: lg,
            },
            values: values,
            originalvalues: values,
            value: value,
            variant: formInfo.data[i][j].variant,
            color: formInfo.data[i][j].color,
            action: formInfo.data[i][j].action,
            addbutton: formInfo.data[i][j].addbutton,
            api: formInfo.data[i][j].apiurl,
            apidefdata: formInfo.data[i][j].apidefdata,
            defaultvalue: formInfo.data[i][j].defaultvalue,
            path: formInfo.data[i][j].path,
            is_mail: formInfo.data[i][j].is_mail === "YES",
            is_mail_message: formInfo.data[i][j].is_mail_message,

            is_name: formInfo.data[i][j].is_name === "YES",
            is_name_message: formInfo.data[i][j].s_name_message,

            is_number: formInfo.data[i][j].is_number === "YES",
            is_number_message: formInfo.data[i][j].is_number_message,

            is_phonenumber: formInfo.data[i][j].is_phonenumber === "YES",
            is_phonenumber_message: formInfo.data[i][j].is_phonenumber_message,

            is_required: formInfo.data[i][j].is_required === "YES",
            is_required_message: formInfo.data[i][j].is_required_message,
          };
          //console.log("finalField",finalField)
          if (validations.length > 0) {
            validations.push("required");
            finalField["validations"] = validations;
            finalField["params"] = params;
          }

          formData.push(finalField);
        } else {
          formData.push({
            name: "space_" + i + "_" + j,
            label: "",
            type: "space",
            size: {
              xs: xs,
              lg: lg,
            },
          });
        }
      }
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
  } finally {
    //  console.log("formData:" , formData)
    return formData;
  }
}

export async function parseDataInfo(_data, postloadscript) {
  try {
    var formData = [];
    var sizeWeb = _data.length;
    var maxSize = 0;

    var appUrl = process.env.REACT_APP_URL_CONFIG;

    for (let i in _data) {
      let tmpSize = _data[i].length;
      if (tmpSize > maxSize) {
        maxSize = tmpSize;
      }
    }
    for (let j = 0; j < maxSize; j++) {
      for (let i in _data) {
        var xs = 12;
        if (sizeWeb <= 2) {
          xs = 12 / sizeWeb;
        } else {
          xs = 6;
        }
        var lg = 12 / sizeWeb;
        if (_data[i][j]) {
          if (_data[i][j].size.xs != "auto") {
            xs = _data[i][j].size.xs;
          }
          if (_data[i][j].size.lg != "auto") {
            lg = _data[i][j].size.lg;
          }
          var values = [];
          var value = _data[i][j].value;
          try {
            values = _data[i][j].values;
            if (_data[i][j].apiurl != "") {
              //console.log("url", _data[i][j].apiurl)
              var apidata = {};
              var _apidata = _data[i][j].apidefdata.split(",");
              for (let d in _apidata) {
                let info = _apidata[d].split("=");
                apidata[info[0]] = info[1];
              }
              //console.log("apidata", apidata)

              values = await ApiClient.getCustomData(
                _data[i][j].apiurl,
                apidata
              );
              if (values.length > 0) {
                var fields = Object.keys(values[0]);
                values.map(function (key, item) {
                  key["value"] = key[fields[0]];
                  key["text"] = key[fields[1]];
                });
                if (_data[i][j].type.indexOf("autocomplete") < 0) {
                  value = values[0][fields[0]];
                }
              }
            }
          } catch (Exc) {
            console.log(
              ">>> Exc getting data",
              _data[i][j].apiurl,
              _data[i][j].apidefdata
            );
          }
          var validations = [];
          var params = {};

          var _validations = _data[i][j].validations;
          Object.entries(_validations).map(function (key, item) {
            if (_validations[key[0]].status) {
              if (key[0] !== "required") {
                if (key[0] == "email") {
                  validations.push("string");
                  validations.push(key[0]);
                } else {
                  validations.push(key[0]);
                }
              }
              if (key[0] === "min") {
                params[key[0]] = [
                  _validations[key[0]].min,
                  _validations[key[0]].message,
                ];
              } else if (key[0] === "max") {
                params[key[0]] = [
                  _validations[key[0]].max,
                  _validations[key[0]].message,
                ];
              } else {
                params[key[0]] = _validations[key[0]].message;
              }
            }
          });

          let finalField = {
            name: _data[i][j].name,
            label: _data[i][j].label,
            placeholder: _data[i][j].label,
            type: _data[i][j].type,
            size: {
              xs: xs,
              lg: lg,
            },
            values: values,
            originalvalues: values,
            value: value,
            variant: _data[i][j].variant,
            color: _data[i][j].color,
            action: _data[i][j].action,
            addbutton: _data[i][j].addbutton,
            api: _data[i][j].apiurl,
            apidefdata: _data[i][j].apidefdata,
          };
          if (validations.length > 0) {
            validations.push("required");
            finalField["validations"] = validations;
            finalField["params"] = params;
          }
          formData.push(finalField);
        } else {
          formData.push({
            name: "space_" + i + "_" + j,
            label: "",
            type: "space",
            size: {
              xs: xs,
              lg: lg,
            },
          });
        }
      }
    }
    if (postloadscript) {
      if ((postloadscript + "").length > 0) {
        try {
          const { simpleGetData } = require("./Functions");
          const { simplePostData } = require("./Functions");
          const utils = require("./Utils");
          await eval(postloadscript);
        } catch (Exc) {
          console.log("EXC:", Exc);
          //toast.error("ERROR"+Exc);
        }
      }
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
  } finally {
    return formData;
  }
}

export function convertToNumber(str) {
  // Try to convert the string into a number
  const number = parseFloat(str);

  // Check if the result is a valid number
  if (!isNaN(number)) {
    return number; // Returns the number if conversion was successful
  } else {
    return str; // Returns the original string if it cannot be converted to a number
  }
}

export function mergeFormData(formData, data) {
  var newformData = formData;
  // console.log("mergeFormData", formData, data )
  try {
    var EXTRADATA;
    for (let key in newformData) {
      var field = newformData[key].name;
      for (let key2 in Object.keys(data)) {
        let field2 = Object.keys(data)[key2];
        if (field2 == "DATA") {
          EXTRADATA = data[field2];
        }
        if (field.toLowerCase() == field2.toLowerCase()) {
          let fieldValue;
          if (Array.isArray(data[field2])) {
            fieldValue = data[field2] ? data[field2] : newformData[key].value;
          } else if (typeof data[field2] === "object") {
            fieldValue = data[field2] ? data[field2] : newformData[key].value;
          } else {
            if (data[field2]) {
              if (!isNaN(data[field2]) && !isNaN(parseFloat(data[field2]))) {
                //console.log("aqui")
                let value = convertToNumber(data[field2]);
                fieldValue = value;
              } else {
                if (data[field2].toString() === "0") {
                  fieldValue = 0;
                } else {
                  fieldValue = data[field2].toString();
                }
              }
            } else {
              if (data[field2].toString() === "0") {
                fieldValue = 0;
              } else {
                fieldValue = newformData[key].value || "";
              }
            }
          }

          if (newformData[key].type == "date") {
            fieldValue = new Date(fieldValue);
          }
          newformData[key].value =
            fieldValue === null ? newformData[key].value : fieldValue;
        }
      }
    }
    if (EXTRADATA) {
      for (let key in newformData) {
        var field = newformData[key].name;
        for (let key2 in Object.keys(EXTRADATA)) {
          let field2 = Object.keys(EXTRADATA)[key2];
          if (field.toLowerCase() == field2.toLowerCase()) {
            let fieldValue = EXTRADATA[field2]
              ? EXTRADATA[field2]
              : newformData[key].value;

            if (newformData[key].type == "date") {
              fieldValue = new Date(fieldValue);
            }
            newformData[key].value =
              fieldValue === null ? newformData[key].value : fieldValue;
          }
        }
      }
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err, formData);
  } finally {
    return newformData;
  }
}

export function completeForm(values, formData) {
  var newvalues = values;
  try {
    for (let key in formData) {
      let field = formData[key];
      if (field.type == "autocomplete") {
        values[field.name] = field.value;
      } else if (field.type == "image") {
        values[field.name] = field.value;
      } else if (field.type == "multipleimages") {
        values[field.name] = field.value;
      } else if (field.type == "componentsliderbanner") {
        values[field.name] = field.value;
      } else if (field.type == "componentcardcontainer") {
        values[field.name] = field.value;
      } else if (field.type == "date") {
        values[field.name] = field.value;
      } else if (field.type == "componentformcontainer") {
        values[field.name] = field.value;
      } else if (field.type == "componentdashboardcontainer") {
        values[field.name] = field.value;
      }
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
  } finally {
    return newvalues;
  }
}

export async function getInitialConf() {
  var response = {};
  try {
    response = await ApiClient.doPost(EndPoint["getInitialConf"]);
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    response = { status: false, message: err };
  } finally {
    return response;
  }
}

export async function saveFlowField(wfpflowid, wfptaskid, name, value) {
  var response = {};
  try {
    let data = {
      varname: name,
      value: value,
      WO_flowID: wfpflowid,
      WO_taskId: wfptaskid,
    };
    response = await ApiClient.doPut(EndPoint["saveFlowField"], data);
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    response = { status: false, message: err };
  } finally {
    return response;
  }
}

export async function prepareCustomPageConfig(pageid) {
  var formattedResponse = { status: false, message: "initial" };
  try {
    let dataCustomMenu = localStorage.getItem(
      process.env.REACT_APP_NAME + "_customMenu"
    );
    let customMenu = [];
    if (dataCustomMenu) {
      if (dataCustomMenu.length > 0) {
        customMenu = JSON.parse(dataCustomMenu);
      }
    }
    let code = pageid.toString();
    let pos = find(customMenu, code, "code");
    var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
    if (!token || pos < 0) {
      formattedResponse = {
        status: false,
        componentConfig: {},
        message: "NO TOKEN",
      };
    } else {
      var response;
      var _response = await ApiClient.doPost(EndPoint["getComponentById"], {
        id: pageid,
      });
      if (_response.status) {
        delete _response.childData["action"];

        let dataStr = JSON.stringify(_response);
        let formattedData = replaceGlobalVars(dataStr);
        response = JSON.parse(formattedData);

        if (response.data.DATA.type === "forms") {
          let formFields = await prepareForm(response.childData);
          let customPage = {
            //validPage: true,
            type: response.data.DATA.type,
            data: formFields,
            datatitle: response.childData.datatitle,
            action: response.data.DATA.action,
          };

          formattedResponse = {
            status: true,
            customPageConfig: customPage,
          };
        } else {
          //let formFields = await prepareForm(response.childData);
          let customPage = {
            //validPage: true,
            type: response.data.DATA.type,
            data: response.childData.data,
            filter: response.childData.filter,
            datatitle: response.childData.datatitle,
          };
          if (response.childData.datapostload) {
            customPage["datapostload"] = response.childData.datapostload;
          }
          formattedResponse = {
            status: true,
            customPageConfig: customPage,
          };
        }
      } else {
        formattedResponse = {
          status: false,
          customPageConfig: response,
          message: "NO DATA",
        };
      }
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = { status: false, message: "ERR:" + err };
  } finally {
    return formattedResponse;
  }
}

export function getTranslateValue(value) {
  return Lngs.defConfig[defLang][value];
}

export function formatNumber(num) {
  if (num === null || num === undefined || isNaN(num) || num === "") {
    return ""; // Devuelve una cadena vacía para valores no válidos
  }
  return parseFloat(num).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

// Función para formatear fechas en "dd/mm/yyyy"
export function formatDate(dateStr) {
  if (
    dateStr === null ||
    dateStr === undefined ||
    !dateStr.includes("T") ||
    !dateStr.includes("Z")
  ) {
    return ""; // Devuelve una cadena vacía para valores no válidos
  }
  const date = new Date(dateStr);
  if (isNaN(date.getTime())) {
    return ""; // Devuelve una cadena vacía si la fecha no es válida
  }

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

// Función para procesar el objeto
export function processRows(obj, start) {
  const processedResult = obj.map((item) => {
    const processedItem = {};

    let step = 0;
    for (const key in item) {
      let isValid = true;
      if (start) {
        if (step < start) {
          isValid = false;
        }
      }
      if (isValid) {
        if (item.hasOwnProperty(key)) {
          const value = item[key];

          // Verificar si es una fecha
          if (
            typeof value === "string" &&
            value.includes("T") &&
            value.includes("Z")
          ) {
            processedItem[key] = formatDate(value);
          }
          // Verificar si es un número
          else if (!isNaN(value) && value !== "") {
            processedItem[key] = formatNumber(value);
          }
          // Caso contrario, mantener el texto
          else {
            processedItem[key] = value;
          }
        }
      } else {
        //console.log("salta")
        const value = item[key];
        processedItem[key] = value;
      }

      step++;
    }

    return processedItem;
  });

  return processedResult;
}

export async function prepareComponentConfig(componentName, data) {
  var formattedResponse = { status: false, message: "initial" };
  try {
    var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
    if (!token) {
      formattedResponse = {
        status: false,
        componentConfig: {},
        message: "NO TOKEN",
      };
    } else {
      var response = await ApiClient.doPost(EndPoint["getDataByCodeLanguage"], {
        code: componentName,
        domainid: 4,
      });
      if (response.status) {
        let defConfig = Lngs.defConfig;
        let field = response.data;
        let rows;
        if (field.CONFIG.getAllDataApiName.indexOf("$MASTER") >= 0) {
          rows = await ApiClient.doMasterPost(
            field.CONFIG.getAllDataApiName.replace("$MASTER", ""),
            field.CONFIG.getAllDataApiData
          );
        } else {
          rows = await ApiClient.doPost(
            field.CONFIG.getAllDataApiName,
            field.CONFIG.getAllDataApiData
          );
        }

        //console.log("rows", rows.result);
        let processedRows = rows.result // processRows(rows.result, 1);

        let componentConfig = {
          componentName: field.CODEDETAILDOMAIN,
          name: field.STRING_VALUE,
          columns: field.CONFIG.columns,
          filters: field.CONFIG.filters,
          getAllDataApiName: field.CONFIG.getAllDataApiName,
          getAllDataApiData: field.CONFIG.getAllDataApiData,
          getSingleDataApiName: field.CONFIG.getSingleDataApiName,
          actions: field.CONFIG.actions,
          writable: field.WRITE,
          rows: processedRows, // <--languages!
        };

        let menu = [];
        let dataMenu = localStorage.getItem(
          process.env.REACT_APP_NAME + "_menu"
        );
        if (dataMenu) {
          if (dataMenu.length > 0) {
            menu = JSON.parse(dataMenu);
          }
        }
        let title = "";
        let componentName = componentConfig.componentName;
        menu.map(function (key) {
          if (key.code == componentName) {
            title = Lngs.defConfig[defLang][key.code]
              ? Lngs.defConfig[defLang][key.code]
              : key.title;
          } else {
            if (key.items) {
              key.items.map(function (key2) {
                if (key2.code == componentName) {
                  title =
                    (Lngs.defConfig[defLang][key.code]
                      ? Lngs.defConfig[defLang][key.code]
                      : key.title) +
                    " > " +
                    (Lngs.defConfig[defLang][key2.code]
                      ? Lngs.defConfig[defLang][key2.code]
                      : key2.title);
                } else {
                  if (key2.items) {
                    key2.items.map(function (key3) {
                      if (key3.code == componentName) {
                        title =
                          (Lngs.defConfig[defLang][key.code]
                            ? Lngs.defConfig[defLang][key.code]
                            : key.title) +
                          " > " +
                          (Lngs.defConfig[defLang][key2.code]
                            ? Lngs.defConfig[defLang][key2.code]
                            : key2.title) +
                          " > " +
                          (Lngs.defConfig[defLang][key3.code]
                            ? Lngs.defConfig[defLang][key3.code]
                            : key3.title);
                      }
                    });
                  }
                }
              });
            }
          }
        });
        let formFields = [];
        if (componentConfig.writable && data) {
          if (
            field.CONFIG.getAllDataApiName === "/customcomponents/get-all" &&
            field.CONFIG.getAllDataApiData.formcode
          ) {
            let responseF = await ApiClient.doPost(
              EndPoint["getTemplateForm"],
              {
                code: field.CONFIG.getAllDataApiData.formcode,
                component: "forms",
              }
            );
            formFields = await prepareForm(responseF.data.DATA, data);
            componentConfig["sigleComponentName"] = field.STRING_VALUE;
            componentConfig["customcomponent"] = true;
          } else {
            let responseF = await ApiClient.doPost(
              EndPoint["getDataByCodeLanguage"],
              data
            );
            formFields = await prepareForm(responseF.data.CONFIG, data);
            componentConfig["sigleComponentName"] = responseF.data.STRING_VALUE;
          }
        } else {
          if (
            field.CONFIG.getAllDataApiName === "/customcomponents/get-all" &&
            field.CONFIG.getAllDataApiData.formcode
          ) {
            componentConfig["sigleComponentName"] = field.STRING_VALUE;
            componentConfig["customcomponent"] = true;
          }
        }
        componentConfig["title"] = title;

        formattedResponse = {
          status: true,
          componentConfig,
          formFields,
        };
      } else {
        formattedResponse = {
          status: false,
          componentConfig: response,
          message: "NO DATA",
        };
      }
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = { status: false, message: "ERR:" + err };
  } finally {
    return formattedResponse;
  }
}

export async function registerNewOrganization(data) {
  var response = { status: false, message: "initial" };
  try {
    var response = await ApiClient.doPut(
      process.env.REACT_APP_MASTER_API_CONFIG +
        "/public/organizations/register-new-user",
      data
    );
    return response;
  } catch (err) {
    console.log(">>>>>>>>>>" + err);
    formattedResponse = { status: false, message: "ERR:" + err };
  } finally {
    return response;
  }
}

export async function createNewOrganization(values, values2) {
  var response = { status: false, message: "initial" };
  try {
    let formData = {
      name: values.name,
      contactfirstname: values.contactfirstname,
      contactlastname: values.contactlastname,
      email: values.contactemail,
      contactemail: values.contactemail,
      password: values.password,
      phonenumber: values.contactphone,
      description: values.description,
      token: values2.token,
      address:
        values.country +
        ", " +
        values.city +
        " " +
        values.address1 +
        " " +
        (values.address2 && values.address2 != "" ? values.address2 : ""),
      tokeninfo: { USERNAME: values.email, type: "public" },
    };
    var response = await ApiClient.doPut(
      process.env.REACT_APP_MASTER_API_CONFIG +
        "/public/organizations/create-new",
      formData
    );
    return response;
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = { status: false, message: "ERR:" + err };
  } finally {
    return response;
  }
}

export async function getComponentReport(filters, filterDefinition, pageId) {
  var formattedResponse = [];
  try {
    let params = {
      filters,
      filterDefinition,
      id: pageId,
    };
    const response = await ApiClient.doPost(
      EndPoint["getComponentReport"],
      params
    );

    //console.log("response", response);

    if (response.status) {
      let components = response.components;
      for (let i in components) {
        let component = components[i];
        let data = [];
        if (component.type === "bar") {
          console.log("es grafico", component.header, response.results[i]);
          let header = component.header[0];
          for (let j in response.results[i]) {
            let row = response.results[i][j];

            let item = {
              x: row[header.xvalue],
            };
            if (row[header.yvalue] && row[header.yvalue] !== "") {
              item["y"] = parseFloat(row[header.yvalue]);
            }

            if (row[header.yvalue2] && row[header.yvalue2] !== "") {
              item["y2"] = parseFloat(row[header.yvalue2]);
            }

            if (row[header.yvalue3] && row[header.yvalue3] !== "") {
              item["y3"] = parseFloat(row[header.yvalue3]);
            }

            data.push(item);
          }
          //console.log("fix data", data);
          component["header"] = header;
          component["data"] = data;
        } else {
          let processedRows = response.results[i]// processRows(response.results[i]);
          //  console.log("processRows", response.results[i], processedRows)
          component["data"] = processedRows; //response.results[i];
        }

        formattedResponse.push(component);
      }
    } else {
      formattedResponse = components;
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = components;
  } finally {
    return formattedResponse;
  }
}

export async function prepareGetHistoryFlows(data) {
  var formattedResponse = { status: false, message: "initial" };
  try {
    const response = await ApiClient.doPost(EndPoint["getHistoryFlows"], data);
    console.log("response", response);
    if (response.status) {
      let flows = [];
      response.flows.map(function (key, index) {
        let row = {
          //sortorder: index+1,
          WFPFLOWID: key.ID,
          flowid: key.FLOWID,
          flowname: key.FLOW,
          version: key.VERSION,
          startDate: new Date(key.STARTDATE),
          endDate: key.ENDDATE ? new Date(key.ENDDATE) : "-",
          office: key.OFFICE,
        };
        for (let v in response.header) {
          let value; //= findValueById( response.flowvars, response.header[v].VARNAME, "VARNAME" , "VARVALUE" )
          let name;
          response.flowvars.map(function (key2) {
            if (
              key.ID === key2.WFPFLOWID &&
              response.header[v].VARNAME == key2.VARNAME
            ) {
              value = key2.VARVALUE;
              name = key2.VARNAME;
            }
          });
          if (value) {
            row[name] = value;
          }
        }
        flows.push(row);
      });

      response.tasks.map(function (key, index) {
        (key["STARTDATE"] = new Date(key.STARTDATE)),
          (key["ENDDATE"] = key.ENDDATE ? new Date(key.ENDDATE) : "-");
      });
      formattedResponse = {
        status: true,
        flows: flows,
        tasks: response.tasks,
        tasksheader: response.header,
      };
    } else {
      formattedResponse = {
        status: false,
        flows: [],
        tasks: [],
        message: "NO DATA",
      };
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = {
      status: false,
      flows: [],
      tasks: [],
      message: "ERR:" + err,
    };
  } finally {
    return formattedResponse;
  }
}

export async function prepareGetHistory(data, url) {
  var formattedResponse = { status: false, message: "initial" };
  try {
    const response = await ApiClient.doPost(url, data);

    if (response.status) {
      let jobs = [];

      response.result.map(function (key, index) {
        try {
          let data = JSON.parse(key.RESPONSE);
          if (data.status) {
            key["STATUS"] = data.status;
          } else {
            if (data.success) {
              key["STATUS"] = data.success;
            }
          }
        } catch (e) {
          console.log("no");
        }
      });

      console.log("response", response);
      formattedResponse = {
        status: true,
        jobs: response.result,
      };
    } else {
      formattedResponse = {
        status: false,
        jobs: [],
        message: "NO DATA",
      };
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = { status: false, jobs: [], essage: "ERR:" + err };
  } finally {
    return formattedResponse;
  }
}

export async function changeTasks(rows, action) {
  var response = { status: false, message: "initial" };
  try {
    response = await ApiClient.doPut(EndPoint["changeTasks"], { rows, action });
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    response = { status: false, message: "ERR:" + err };
  } finally {
    return response;
  }
}

export async function prepareGetHistoryFlow(data) {
  var formattedResponse = { status: false, message: "initial" };
  try {
    const response = await ApiClient.doPost(EndPoint["getHistoryFlow"], data);
    if (response.status) {
      formattedResponse = {
        status: true,
        flows: response.flows,
        tasks: response.tasks,
        //tasksheader: response.header,
      };
    } else {
      formattedResponse = {
        status: false,
        flows: [],
        tasks: [],
        message: "NO DATA",
      };
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = {
      status: false,
      flows: [],
      tasks: [],
      message: "ERR:" + err,
    };
  } finally {
    return formattedResponse;
  }
}

export async function getResumeTasksByUser(data) {
  var formattedResponse = { status: false, message: "initial" };
  try {
    var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
    if (token) {
      formattedResponse = await ApiClient.doPost(
        EndPoint["getResumeTasksByUser"],
        data
      );
    } else {
      formattedResponse = { status: false, message: "NO TOKEN" };
    }
  } catch (err) {
    console.log(">>>>>>>>>>" + err);
    formattedResponse = {
      status: false,
      activetasks: [],
      message: "ERR:" + err,
    };
  } finally {
    return formattedResponse;
  }
}

export async function prepareGetFlowVars(data) {
  var response = { status: false, message: "initial" };
  try {
    response = await ApiClient.doPost(EndPoint["getFlowVars"], data);
  } catch (err) {
    console.log(">>>>>>>>>>" + err);
    response = { status: false, result: [], message: "ERR:" + err };
  } finally {
    return response;
  }
}
//recuperar tareas pendientes
export async function prepareGetTasks(data) {
  //console.log("prepareGetTasks");
  var formattedResponse = { status: false, message: "initial" };
  try {
    const response = await ApiClient.doPost(
      EndPoint["getActiveTasksByUser"],
      data
    );

    if (response.status) {
      let tasks = [];
      let tmpWfpTaskIds = [];

      let userInfo = getUserInfo();
      let userId = parseInt(userInfo.USERID);
      let curDate = new Date();
      response.tasks.map(function (key, index) {
        var subtasks = [];
        if (tmpWfpTaskIds.indexOf(key.ID) < 0) {
          //console.log("ID" , key.ID, tmpWfpTaskIds  )
          tmpWfpTaskIds.push(key.ID);
          let options = [];
          response.tasks.map(function (key2) {
            if (key.ID === key2.ID) {
              //console.log("si",key2.OPTIONVALUE)
              options.push(key2.OPTIONVALUE ? key2.OPTIONVALUE : "NO___OPTION");
            }
          });
          //console.log(userId , key.USERID)

          let taskDate = new Date(key.STARTDATE);
          //if ( )
          let day = "All";

          let addMlSeconds = key.TIMEOUT * 60 * 60000;
          let numberOfMlSeconds = new Date(key.STARTDATE).getTime();
          let fixedCurDate = new Date(numberOfMlSeconds + addMlSeconds);
          let locked = false;
          if (key.USERID != 0) {
            if (userId != key.USERID) {
              locked = true;
            }
          }

          let row = {
            sortorder: index + 1,
            userowner: userId == key.USERID ? 1 : key.USERID == "0" ? 2 : 3,
            subtasks: [],
            id: key.ID,
            hasChild: false,
            flowname: key.FLOW.toLowerCase(),
            taskname: key.TASK.toLowerCase(),
            name: key.TASK + "__" + key.ID,
            officecode: key.OFFICECODE,
            wfpflowid: key.WFPFLOWID,
            options: options,
            startDate: new Date(key.STARTDATE),
            progress: key.PROGRESS,
            commentary: key.ADDCOMMENTARY,
            parentid: key.PARENTID,
            formcode: key.FORMCODE,
            tasktypeId: key.TASKTYPEID,
            isassignable: key.ISASSIGNABLE,
            isgroupable: key.ISGROUPABLE,
            day: day,
            assigned: userId == key.USERID,
            expired: key.TIMEOUT === 0 ? false : curDate > fixedCurDate,
            timeout: key.TIMEOUT,
            flowid: key.FLOWID,
            taskid: key.TASKID,
            locked: locked,
          };
          //console.log("response.header",response.header,response.flowvars)

          let hiddenRows = [];
          for (let i = 0; i < response.header.length; i++) {
            if (response.header[i].HIDDEN === "YES") {
              hiddenRows.push(response.header[i].VARNAME);
              response.header.splice(i, 1);
              i--;
            }
          }

          for (let i = 0; i < response.flowvars.length; i++) {
            if (hiddenRows.indexOf(response.flowvars[i].VARNAME) >= 0) {
              //response.flowvars.splice(i, 1);
              //i--;
              response.flowvars[i]["hidden"] = true;
            }
          }
          //console.log("response.header ",response.header)
          for (let v in response.header) {
            let value; //= findValueById( response.flowvars, response.header[v].VARNAME, "VARNAME" , "VARVALUE" )

            response.flowvars.map(function (key2) {
              if (
                key.WFPFLOWID === key2.WFPFLOWID &&
                response.header[v].VARNAME == key2.VARNAME
              ) {
                value = key2.VARVALUE;
              }
            });

            if (value) {
              row["field" + v] = value;
            }
          }
          tasks.push(row);
        }
      });

      let parentIds = [];
      let posToDelete = [];
      tasks.map(function (key, index) {
        let progress = key.progress;
        let totGroupedTasks = 0;
        if (key.parentid > 0) {
          let subtasks = [];
          let arrIds = [];
          let arrFlowIds = [];

          let arrF0 = [];
          let arrF1 = [];
          let arrF2 = [];
          let arrF3 = [];
          let arrF4 = [];
          let arrF5 = [];
          let arrF6 = [];
          if (parentIds.indexOf(key.parentid) < 0) {
            parentIds.push(key.parentid);
            tasks.map(function (keyB, indexB) {
              if (key.parentid === keyB.parentid) {
                arrIds.push(keyB.id);
                arrFlowIds.push(keyB.wfpflowid);
                progress += keyB.progress;
                totGroupedTasks++;
                if (key.id !== keyB.id) {
                  posToDelete.push(indexB);
                }
                let row = { ...keyB };
                delete row["hasChild"];
                delete row["subtasks"];
                subtasks.push(row);
                if (keyB.field0) {
                  let pos = arrF0.findIndex((item) => item === keyB.field0);
                  if (pos < 0) {
                    arrF0.push(keyB.field0);
                  }
                }
                if (keyB.field1) {
                  let pos = arrF1.findIndex((item) => item === keyB.field1);
                  if (pos < 0) {
                    arrF1.push(keyB.field1);
                  }
                }
                if (keyB.field2) {
                  let pos = arrF2.findIndex((item) => item === keyB.field2);
                  if (pos < 0) {
                    arrF2.push(keyB.field2);
                  }
                }
                if (keyB.field3) {
                  let pos = arrF3.findIndex((item) => item === keyB.field3);
                  if (pos < 0) {
                    arrF3.push(keyB.field3);
                  }
                }
                if (keyB.field4) {
                  let pos = arrF4.findIndex((item) => item === keyB.field4);
                  if (pos < 0) {
                    arrF4.push(keyB.field4);
                  }
                }
                if (keyB.field5) {
                  let pos = arrF5.findIndex((item) => item === keyB.field5);
                  if (pos < 0) {
                    arrF5.push(keyB.field5);
                  }
                }

                if (keyB.field6) {
                  let pos = arrF6.findIndex((item) => item === keyB.field6);
                  if (pos < 0) {
                    arrF6.push(keyB.field6);
                  }
                }
              }
            });

            //console.log( "xxx",subtasks, arrIds)

            if (arrIds.length > 1) {
              key["id"] = arrIds;
              key["wfpflowid"] = arrFlowIds;
              key["hasChild"] = true;
              key["subtasks"] = subtasks;
              key["totalTasks"] = totGroupedTasks;
              key["progress"] = parseInt(progress / totGroupedTasks);
              key["field0"] = arrF0.length > 1 ? arrF0 : key["field0"];
              key["field1"] = arrF1.length > 1 ? arrF1 : key["field1"];
              key["field2"] = arrF2.length > 1 ? arrF2 : key["field2"];
              key["field3"] = arrF3.length > 1 ? arrF3 : key["field3"];
              key["field4"] = arrF4.length > 1 ? arrF4 : key["field4"];
              key["field5"] = arrF5.length > 1 ? arrF5 : key["field5"];
              key["field6"] = arrF6.length > 1 ? arrF6 : key["field6"];
            }
          }
          //          console.log("key",key)
        }
      });
      let finalTasks = [];
      for (let r in tasks) {
        if (posToDelete.indexOf(parseInt(r)) < 0) {
          finalTasks.push(tasks[r]);
        }
      }
      finalTasks.sort(function (a, b) {
        return b.id > a.id ? -1 : b.id < a.id ? 1 : 0;
      });

      finalTasks.sort((a, b) => {
        let af0 = a.field0 || "XXXXX";
        let bf0 = b.field0 || "XXXXX";

        let af1 = a.field1 || "XXXXX";
        let bf1 = b.field1 || "XXXXX";

        let auser = a.userowner | "XXXXXX";
        let buser = b.userowner | "XXXXXX";

        //console.log("a", af1, Array.isArray(af1))
        if (af0 !== bf0) {
          if (isNaN(af0)) {
            if (Array.isArray(af0) || Array.isArray(bf0)) {
              return af0 - bf0;
            } else {
              return af0.localeCompare(bf0);
            }
          } else {
            return af0 - bf0;
          }
        }
        if (af1 !== bf1) {
          if (isNaN(af1)) {
            if (Array.isArray(af1) || Array.isArray(bf1)) {
              return af1 - bf1;
            } else {
              return af1.localeCompare(bf1);
            }
          } else {
            return af1 - bf1;
          }
        }
        // Si el segundo campo también es igual, comparar por el tercer campo
        return auser - buser;
      });
      /*
      finalTasks.sort(function (a, b) {
        return b.userowner > a.userowner
          ? -1
          : b.userowner < a.userowner
          ? 1
          : 0;
      });*/
      /*
      console.log(
        "finalTasks",
        finalTasks,
        posToDelete,
        response.header,
        response.flowvars
      );*/
      formattedResponse = {
        status: true,
        activetasks: finalTasks,
        tasksheader: response.header,
        flowvars: response.flowvars,
      };
    } else {
      formattedResponse = {
        status: false,
        activetasks: [],
        message: "NO DATA",
      };
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = {
      status: false,
      activetasks: [],
      message: "ERR:" + err,
    };
  } finally {
    return formattedResponse;
  }
}

export async function testRestApi(_formData) {
  let response = { status: false, message: "invalid params" };
  try {
    let formData = {
      apidata: _formData.apidata,
      apiurl: _formData.api,
      apitoken: _formData.apitoken,
      apimethod: _api[0],
      apitypemethod: _api[1],
      scriptbody: _formData.preloadscript,
      scriptpostload: _formData.postloadscript,
      apiauthtoken: _formData.apitoken,
      apiprocesstype: "RESTAPI",
    };
    console.log("formData", formData);
    response = await ApiClient.doPost(EndPoint.testApi, formData);
    /*
		let data = {}
		console.log("formData", formData)
		var _apidata = formData.apidata.split("|")
		var apiUrl = formData.api

		
		const engine = require("./Utils");

//		console.log(_apidata)
		var WO_params ={}
		var WO_flowID = 0
		var WO_TaskID = 0
		var WO_officecode = ""
		var WO_rest={}
		var optionValue
		if(formData.api.indexOf("http")<0 ){
			apiUrl = process.env.REACT_APP_API_CONFIG+ formData.api
		}	

		for (let d  in _apidata){
			if (_apidata[d]!=""){
				let info = _apidata[d].split("=")
				//console.log(info)
				if(info[1]){
					if (info[1].indexOf ('<<')>=0 ){
						let _WO_param = info[1].split('>>');
						let WO_param  = _WO_param [0].replace("<<","") 
						data[info[0]] = WO_params[WO_param]?WO_params[WO_param]:"NA"
					}else{
						data[info[0]] = info[1]
					}
				}
			}
		}

		var apiHeaders = { "Content-Type":"application/json", "Accept": "*" }

		if ( formData.apitoken!==""){
			var _apiHeaders = formData.apitoken.split(",")				
			for (let d  in _apiHeaders){
				let info = _apiHeaders[d].split("=")
				apiHeaders[info[0]] = info[1]
			}
		}
		
 
		const configOptions = {
			headers: apiHeaders,
		};
		 
		let isValid =  true 
		if ((formData.preloadscript!=="")){

			let preloadscript = formData.preloadscript 
			if (preloadscript.indexOf ('<<')>=0 ){
				let fixedPreload=""					
				let _WO_param = preloadscript.split('<<');
				fixedPreload = _WO_param[0]
				for (let p in _WO_param){					
					if (parseInt(p)>0){
						let param = _WO_param[p].split(">>")
						let fixedRow = (WO_params[param[0]]?WO_params[param[0]]:"NA" ) +param[1]
						fixedPreload+=fixedRow
					}
				}				
				preloadscript= fixedPreload				
			}
			

			try{
				if (preloadscript.indexOf ("async")>=0 || preloadscript.indexOf ("await")>=0){
					await eval( " (async () => {" + preloadscript + "})();")
				}else{							
					eval(preloadscript)
				}
			}catch(Exc){
				isValid= false
				console.log("error preload ", Exc)
				response["message"] = "ERR Preload:"+Exc
			}
		}
		if(isValid){
			//console.log("data",data)
			try{
				if (formData.apimethod.indexOf("GET")>=0){
					await axios.get(
						apiUrl,
						data,
						configOptions,
					)
					.then(response => response.data)
					.then(resp => {
						response= resp
					})
					.catch(function(error) {
						console.log(">>>>>>>> Error api",error);
						response = {success:false, message:"ERR:"+error}
					});	
				}else if (formData.apimethod.indexOf("POST")>=0){
					await axios.post(
						apiUrl,
						data,
						configOptions,
					)
					.then(response => response.data)
					.then(resp => {
						response= resp
					})
					.catch(function(error) {
						console.log(">>>>>>>> Error api",error);
						response = {success:false, message:"ERR:"+error}
					});	
				}else if (formData.apimethod.indexOf("PUT")>=0){
					await axios.put(
						apiUrl,
						data,
						configOptions,
					)
					.then(response => response.data)
					.then(resp => {
						response= resp
					})
					.catch(function(error) {
						console.log(">>>>>>>> Error api",error);
						response = {success:false, message:"ERR:"+error}
					});	
				}

				if ((formData.postloadscript!=="")){
					try{
						if (formData.postloadscript.indexOf ("async")>=0 || formData.postloadscript.indexOf ("await")>=0){
							await eval( " (async () => {" + formData.postloadscript + "})();")		
						}else{							
							eval(formData.postloadscript)
						}
					}catch(Exc){
						console.log("error postload ", Exc)
						response["ERROR POSTLOAD"] = "ERR Postload:"+Exc
					}
				}

			}catch (error){
				response = {success:false, message:"ERR:"+error}
			}	
		}
		*/
    return response;
  } catch (error) {
    console.log("ERR", error);
    response = { success: false, message: "ERR:" + error };
  }
}

export async function testJS(_formData) {
  let response = { status: false, message: "invalid params" };
  try {
    let data = {};

    let formData = {
      apidata: _formData.apidata,
      apiurl: _formData.api,
      apitoken: _formData.apitoken,
      apimethod: "",
      apitypemethod: "",
      scriptbody: _formData.preloadscript,
      scriptpostload: _formData.postloadscript,
      apiauthtoken: _formData.apitoken,
      apiprocesstype: "JS",
    };
    //console.log("formData",formData)
    response = await ApiClient.doPost(EndPoint.testApi, formData);

    /*
		var _apidata = formData.apidata.split("|")
		
		const engine = require("./Utils");

//		console.log(_apidata)
		var WO_params ={}
		var WO_flowID = 0
		var WO_TaskID = 0
		var WO_officecode = ""
		var WO_rest={}
		var optionValue

		for (let d  in _apidata){
			if (_apidata[d]!=""){
				let info = _apidata[d].split("=")
				console.log(info)
				if(info[1]){
					if (info[1].indexOf ('<<')>=0 ){
						let _WO_param = info[1].split('>>');
						let WO_param  = _WO_param [0].replace("<<","") 
						data[info[0]] = WO_params[WO_param]?WO_params[WO_param]:"NA"
					}else{
						data[info[0]] = info[1]
					}
				}
			}
		}
		

		
		 
		let isValid =  true 
		if ((formData.preloadscript!=="")){

			let preloadscript = formData.preloadscript 
			if (preloadscript.indexOf ('<<')>=0 ){
				let fixedPreload=""					
				let _WO_param = preloadscript.split('<<');
				fixedPreload = _WO_param[0]
				for (let p in _WO_param){					
					if (parseInt(p)>0){
						let param = _WO_param[p].split(">>")
						let fixedRow = (WO_params[param[0]]?WO_params[param[0]]:"NA" ) +param[1]
						fixedPreload+=fixedRow
					}
				}				
				preloadscript= fixedPreload				
			}
			

			try{
				if (preloadscript.indexOf ("async")>=0 || preloadscript.indexOf ("await")>=0){
					await eval( " (async () => {" + preloadscript + "})();")
				}else{							
					eval(preloadscript)
				}
			}catch(Exc){
				isValid= false
				console.log("error preload ", Exc)
				response["message"] = "ERR Preload:"+Exc
			}
		}
		if(isValid){
			console.log("data",data)
			try{
				
				if ((formData.postloadscript!=="")){
					try{
						if (formData.postloadscript.indexOf ("async")>=0 || formData.postloadscript.indexOf ("await")>=0){
							await eval( " (async () => {" + formData.postloadscript + "})();")
						}else{							
							eval(formData.postloadscript)
						}
					}catch(Exc){
						console.log("error postload ", Exc)
						response["ERROR POSTLOAD"] = "ERR Postload:"+Exc
					}
				}

			}catch (error){
				response = {success:false, message:"ERR:"+error}
			}	
		}
		*/
    return response;
  } catch (error) {
    console.log("ERR", error);
    response = { success: false, message: "ERR:" + error };
  }
}

export async function saveCommentary(data) {
  let response = { status: false, message: "initial" };
  try {
    response = await ApiClient.doPut(EndPoint["saveCommentary"], data);
    response["refreshResumeTasks"] = response.status;
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    response = {
      status: false,
      tasktoclear: -1,
      message: "ERR:" + err,
    };
  } finally {
    return response;
  }
}

export async function prepareExecuteTask(data) {
  var response = { status: false, message: "initial" };
  try {
    response = await ApiClient.doPut(EndPoint["executeTask"], data);
    response["refreshResumeTasks"] = response.status;
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    response = {
      status: false,
      tasktoclear: -1,
      message: "ERR:" + err,
    };
  } finally {
    return response;
  }
}

export function replaceGlobalVars(strData) {
  let resp = strData;
  try {
    let globalVars = [];
    let _globalVars = localStorage.getItem(
      process.env.REACT_APP_NAME + "_orgGlobalVars"
    );
    if (_globalVars) {
      globalVars = JSON.parse(_globalVars);
      for (let i in globalVars) {
        let key = Object.keys(globalVars[i])[0];
        let rKey = globalVars[i][key];
        resp = resp.replaceAll("${" + key + "}", rKey);
      }
    }
    //console.log("resp", resp);
    return resp;
  } catch (Exc) {
    console.log(">>>>>>>", Exc);
    return strData;
  }
}

export async function getTemplateForm(formCode) {
  var formattedResponse = { status: false, message: "initial" };
  try {
    let response = await ApiClient.doPost(EndPoint["getTemplateForm"], {
      code: formCode,
      component: "forms",
    });
    if (response.status) {
      if (response.data.DATA) {
        let dataStr = JSON.stringify(response.data.DATA.data);

        let formattedData = replaceGlobalVars(dataStr);
        let data = JSON.parse(formattedData);
        formattedResponse = {
          status: true,
          data: data,
          datatitle: response.data.DATA.datatitle,
          datasubtitle: response.data.DATA.datasubtitle,
          datapostload: response.data.DATA.datapostload,
          action: response.data.DATA.action, //not used (CHECK)
        };
      } else {
        formattedResponse = {
          status: true,
          data: [],
        };
      }
    } else {
      formattedResponse = {
        status: false,
        message: "NO DATA",
      };
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = { status: false, message: "ERR:" + err };
  } finally {
    return formattedResponse;
  }
}

export function getSyncComponentRows(getAllDataApiName) {
  var formattedResponse = { status: false, message: "initial" };
  try {
    ApiClient.doPost(getAllDataApiName, {})
      .then((response) => {
        if (response.status) {
          let rows = response.result;

          formattedResponse = {
            status: true,
            rows: rows,
          };
          return formattedResponse;
        } else {
          formattedResponse = {
            status: false,
          };
          return formattedResponse;
        }
      })
      .catch(function (error) {
        formattedResponse = {
          status: false,
        };
        return formattedResponse;
      });
  } catch (err) {
    console.log(">>>>>>>>>>", err);
    formattedResponse = { status: false, message: "ERR:" + err };
    return formattedResponse;
  }
}

export function getImagePath(ImagePath) {
  try {
    if (process.env.REACT_APP_PATH_IMAGES) {
      if (ImagePath.indexOf("http") >= 0) {
        return ImagePath;
      } else if (ImagePath.indexOf("/static") >= 0) {
      } else if (ImagePath.length > 2000) {
        return `data:image/jpeg;base64,${ImagePath}`;
      } else {
        return process.env.REACT_APP_PATH_IMAGES + "" + ImagePath;
      }
    } else {
      return ImagePath;
    }
  } catch (Excep) {
    return "";
  }
}
export async function simplePostData(url, data) {
  try {
    /*
    var instance = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_URL });
    const response = await instance.post(url, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
    */

    let response = await ApiClient.doPost(url, data);

    return response;
  } catch (error) {
    console.log("error>>>>", url, data, error);
    return null;
  }
}

export async function simpleGetData(url, data) {
  try {
    var instance = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_URL });
    const response = await instance.get(url, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error>>>>", url, data, error);
    return null;
  }
}

export function convertArrayToObj(objectField, fieldToEvaluate) {
  try {
    if (Array.isArray(objectField)) {
      let formData = {};
      objectField.map((key) => {
        if (fieldToEvaluate) {
          formData[key[fieldToEvaluate]] = key;
        } else {
          formData[key.name] = key;
        }
      });

      return formData;
    } else {
      return objectField;
    }
  } catch (error) {
    console.log("error>>>>", error, objectField);
    return objectField;
  }
}

export function convertObjToArray(objectField, sortValue) {
  try {
    if (typeof objectField === "object") {
      var arrayField = [];
      Object.keys(objectField).map((key) => {
        arrayField.push(objectField[key]);
      });
      if (sortValue) {
        arrayField.sort(function (a, b) {
          return b[sortValue] > a[sortValue]
            ? -1
            : b[sortValue] < a[sortValue]
            ? 1
            : 0;
        });
      }

      return arrayField;
    } else {
      return objectField;
    }
  } catch (error) {
    console.log("error>>>>", error, objectField);
    return objectField;
  }
}

export async function prepareForm(_formData, data, apiName) {
  var formData = {};

  //console.log("data", _formData);
  try {
    if (Array.isArray(_formData)) {
      formData = _formData;
      //console.log("arr")
      let resp = await preloadForm(formData);
    } else {
      //console.log("obj")
      formData = await parseFormInfo(_formData); //customreporte use obj
    }
    if (data) {
      if (data.id) {
        if (data.id !== 0) {
          let row = {};
          if (apiName) {
            row = await ApiClient.doPost(apiName, data);
          } else {
            row = await ApiClient.doPost(data.code + "/" + "get-single", data);
          }

          if (row.status) {
            formData = mergeFormData(formData, row.data);
          }
        }
      } else {
        console.log("NO ID");
      }
    }

    if (_formData.datapostload) {
      if ((_formData.datapostload + "").length > 0) {
        try {
          //public apis
          const { simpleGetData } = require("./Functions");
          const { simplePostData } = require("./Functions");
          const utils = require("./Utils");
          formData = convertArrayToObj(formData);

          if (
            _formData.datapostload.indexOf("async") >= 0 ||
            _formData.datapostload.indexOf("await") >= 0
          ) {
            await eval(" (async () => {" + _formData.datapostload + "})();");
          } else {
            eval(_formData.datapostload);
          }
          formData = convertObjToArray(formData, "layout_row");
        } catch (Exc) {
          console.log("EXC:", Exc);
          //toast.error("<Error postload Action>"+Exc);
        }
      }
    }
  } catch (error) {
    console.log("error>>>>", _formData, error);
    return {};
  } finally {
    return formData;
  }
}

export async function validateForm(_formData) {
  var validResponse = { messages: [], formData: {} };
  try {
    if (Array.isArray(_formData)) {
      for (let i in _formData) {
        let field = _formData[i];
        let isValid = true;
        let message = "";

        if (field.is_required) {
          if (field.value === "" || (!field.value && field.value != 0)) {
            isValid = false;
            field["invalid"] = true;
            //            message = Lngs.defConfig[defLang][value]+" " + field.label;
            message = "Invalid " + field.label;
            if (field.is_required_message) {
              if (field.is_required_message != "") {
                message = field.is_required_message;
              }
            }
          }
        }

        if (field.is_mail && field.value != "") {
          let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
          if (!validEmail.test(field.value)) {
            isValid = false;
            field["invalid"] = true;
            message = "Invalid " + field.label;
            if (field.is_mail_message) {
              if (field.is_mail_message != "") {
                message = field.is_mail_message;
              }
            }
          }
        }

        if (field.is_phonenumber && field.value != "") {
          let phoneRegExp =
            /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
          if (!phoneRegExp.test(field.value)) {
            isValid = false;
            field["invalid"] = true;
            message = "Invalid " + field.label;
            if (field.is_phonenumber_message) {
              if (field.is_phonenumber_message != "") {
                message = field.is_phonenumber_message;
              }
            }
          }
        }
        if (field.is_name && field.value != "") {
          let nameRegExp = RegExp(/^[A-Za-z0-9\s]+$/g);
          if (!nameRegExp.test(field.value)) {
            isValid = false;
            field["invalid"] = true;
            message = "Invalid " + field.label + "(just letters and numbers)";
            if (field.is_name_message) {
              if (field.is_name_message != "") {
                message = field.is_name_message;
              }
            }
          }
        }

        if (field.is_number && field.value != "") {
          if (isNaN(field.value)) {
            isValid = false;
            field["invalid"] = true;
            message = "Invalid " + field.label + "(numbers)";
            if (field.is_name_message) {
              if (field.is_name_message != "") {
                message = field.is_name_message;
              }
            }
          }
        }

        if (field.is_password && field.value != "") {
          let pos = _formData.findIndex(
            (item) => item.name === field.name + "2"
          );
          //console.log(pos, _formData[pos])
          let passExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
          if (field.value != _formData[pos].value) {
            isValid = false;
            field["invalid"] = true;
            message = "The password and confirmation password do not match";
          }
          if (!passExp.test(field.value)) {
            isValid = false;
            field["invalid"] = true;
            message =
              "Min 8 Characters, 1 Uppercase Letter, 1 Lowercase Letter and 1 Number";
          }
        }

        if (!isValid) {
          field["invalid_message"] = message;
          validResponse.messages.push({ name: field.name, message: message });
        } else {
          field["invalid"] = false;
        }
      }
    } else {
    }

    validResponse["formData"] = _formData;
  } catch (error) {
    console.log("error>>>>", _formData, error);
    validResponse = { messages: [{ error: true }], formData: {} };
  } finally {
    return validResponse;
  }
}

export function clearformData(_formData) {
  var formData = _formData;
  //console.log("_formData to clear", _formData)
  try {
    for (let field in _formData) {
      //var field = Object.keys(_formData)[key]
      let fieldValue = "";
      if (
        _formData[field].type === "options" ||
        _formData[field].type === "optionsGrid" ||
        _formData[field].type === "optionsChart"
      ) {
        //console.log ("_formData[field]",_formData[field])
        fieldValue = undefined;
      } else if (
        _formData[field].type === "select" ||
        _formData[field].type === "radio"
      ) {
        if (_formData[field].values?.datatype === "static") {
          fieldValue = _formData[field].values.options[0]
            ? _formData[field].values.options[0].value
            : "";
        } else {
          fieldValue = _formData[field].values[0]
            ? _formData[field].values[0].value
            : "";
        }
      } else if (_formData[field].type === "multiple") {
        fieldValue = [];
      } else if (_formData[field].type === "switch") {
        fieldValue = "NO";
      } else if (_formData[field].type === "checkbox") {
        fieldValue = [];
      } else if (_formData[field].type === "datepicker") {
        fieldValue = new Date();
      } else if (_formData[field].type === "hidden") {
        fieldValue = _formData[field].value;
      }
      _formData[field].value = _formData[field].defultvalue
        ? _formData[field].defultvalue
        : fieldValue;
      _formData[field].isInvalid = false;
    }
    formData = _formData;
  } catch (err) {
    console.log(">>>>>>>>>>", err);
  } finally {
    //console.log("RET",formData)
    return formData;
  }
}

export function parseFormData(_formData) {
  var parsedFormData = {};
  try {
    for (let key in _formData) {
      var field = _formData[key];
      let fieldValue = field.value;
      parsedFormData[field.name.toLowerCase()] = fieldValue;
    }
  } catch (err) {
    console.log(">>>>>>>>>>", err);
  } finally {
    return parsedFormData;
  }
}

export function extraerValores(string) {
  // Expresión regular para encontrar los valores dentro de corchetes
  const regex = /\[([^\]]+)\]|([^[\]]+)/g;

  // Array para almacenar los objetos con name y type
  const objetos = [];

  // Iteramos sobre todos los matches encontrados en el string
  let match;
  while ((match = regex.exec(string)) !== null) {
    // El match[1] contendrá el valor dentro de los corchetes si existe, de lo contrario match[2]
    const valor = match[1] || match[2];
    if (valor) {
      // Determinar el tipo basado en la presencia de corchetes
      const tipo = match[1] ? "variable" : "text";
      // Agregar objeto con name y type al array objetos
      objetos.push({ name: valor, type: tipo });
    }
  }

  return objetos;
}
//abre datos de la tarea
export async function preparTaskForm(
  id,
  searchField,
  taskData,
  taskvars,
  mainheader,
  fortemporalyIds
) {
  /*
  console.log("taskData", taskData);
  console.log("taskvars", taskvars); 
  console.log("mainheader", mainheader);
  console.log("fortemporalyIds", fortemporalyIds);
  */
  let response = { formDataTask: [], formDialogStatus: false };
  try {
    let formcode;
    let pos = find(taskData, id, searchField);
    let task = {};
    let wfptaskid;
    let wfpflowid;
    let officecode;
    if (fortemporalyIds) {
      let subtasks = [];
      wfpflowid = [];
      wfptaskid = [];
      for (let t in taskData) {
        let ttask = taskData[t];
        if (fortemporalyIds.indexOf(ttask.id) >= 0 && ttask.userowner <= 2) {
          pos = find(taskData, fortemporalyIds[0], "id");
          subtasks.push(ttask);
          wfpflowid.push(ttask.wfpflowid);
          wfptaskid.push(ttask.id);
        }
      }
      let _tmpT = taskData[pos];
      task = {
        flowname: _tmpT.flowname,
        formcode: _tmpT.formcode,
        hasChild: true,
        id: wfptaskid,
        name: _tmpT.name,
        officecode: _tmpT.officecode,
        options: _tmpT.options,
        parentid: wfptaskid.toString(),
        progress: 0,
        sortorder: _tmpT.sortorder,
        startDate: _tmpT.startDate,
        subtasks: subtasks,
        taskname: _tmpT.taskname,
        tasktypeId: _tmpT.tasktypeId,
        totalTasks: _tmpT.totalTasks,
        userowner: _tmpT.userowner,
        wfpflowid: wfpflowid,
      };
      formcode = task.formcode;
      officecode = task.officecode;
    } else {
      if (pos >= 0) {
        task = taskData[pos];
        formcode = task.formcode;
        wfpflowid = task.wfpflowid;
        wfptaskid = task.id;
        officecode = task.officecode;
      } else {
        pos = find(taskData, id, searchField, "subtasks");
        task = taskData[pos[0]].subtasks[pos[1]];
        formcode = task.formcode;
        officecode = task.officecode;
      }
    }
    //console.log("task", task)

    let flowData = [];

    let tempFlowValues = {};
    let isParent = false;

    //let resp = await this.getFormInfo(formcode);

    let resp = await getTemplateForm(formcode);

    let formData = [];

    resp.data.map(function (field) {
      if (field.defaultvalue) {
        if (
          field.defaultvalue.indexOf("[") >= 0 &&
          field.defaultvalue.indexOf("]") >= 0
        ) {
          let defaultvalue = field.defaultvalue;

          const string = field.defaultvalue;
          const resp = extraerValores(string);
          if (resp.length > 0) {
            defaultvalue = resp[0].name;
            if (resp.length > 1) {
              for (let i = 1; i < resp.length; i++) {
                let field = resp[i];
                if (field.name == "wfptaskid") {
                  defaultvalue += id;
                } else {
                  if (field.type === "variable") {
                    let pos = taskvars.findIndex(
                      (item) => item.VARNAME === field.name
                    );
                    if (pos >= 0) {
                      defaultvalue += taskvars[pos].VARVALUE;
                    }
                  } else {
                    defaultvalue += field.name;
                  }
                }
              }
            }
          }
          field["defaultvalue"] = defaultvalue;
        }
      }
    });

    if (resp.status) {
      var taskHeader = "";
      var taskName = task.taskname;
      var commentary = task.commentary ? task.commentary.split("|_|") : [];
      commentary = commentary.reverse();
      formData = resp.data;
      var headerObj = convertArrayToObj(mainheader, "VARNAME");
      let field;
      var taskId;
      if (mainheader.length > 0) {
        field = mainheader[0];
      }

      if (task.subtasks?.length > 0) {
        //multiple tasks
        let taskids = task.id;
        let flowids = task.wfpflowid;
        //console.log("multiple",taskids,flowids)
        taskids.sort(function (a, b) {
          return a - b;
        });

        flowids.sort(function (a, b) {
          return a - b;
        });

        wfptaskid = task.parentid + "___" + taskids[0];
        wfpflowid = flowids[0];
        taskId = task.parentid;
        isParent = true;
        taskHeader = ""; //task.taskname

        let vars = taskvars;
        let firstTime = true;
        task.subtasks.map(function (subtask) {
          let data = [];
          for (let k in vars) {
            let key = vars[k];
            if (key.WFPFLOWID == subtask.wfpflowid) {
              let fieldValue = {
                name: key.VARNAME,
                label: headerObj[key.VARNAME]
                  ? headerObj[key.VARNAME].VARLABEL
                  : key.VARNAME,
                value: key.VARVALUE,
                type: "text",
                layout_id: 99999,
                // disabled : true,
                layout_sizeX: 6,
              };
              data.push(fieldValue);
            }
          }
          flowData.push({
            header:
              (process.env.REACT_APP_CUSTOM_TASKLABEL || "Tarea") +
              ": " +
              subtask.wfpflowid,
            data: data,
            label: data[0]?.label,
            key: data[0]?.value,
            wfpflowid: subtask.wfpflowid,
            wfptaskid: subtask.id,
          });
        });
        flowData.sort(function (a, b) {
          return b.key > a.key ? -1 : b.key < a.key ? 1 : 0;
        });
        flowData.map(function (key) {
          if (field) {
            if (firstTime) {
              firstTime = false;
              taskHeader += /*" - "+*/ key.label + "(s): " + key.key;
            } else {
              taskHeader += ", " + key.key;
            }
          } else if (!field) {
            if (firstTime) {
              firstTime = false;
              taskHeader +=
                /*" - "+*/ (process.env.REACT_APP_CUSTOM_TASKLABEL || "Tarea") +
                ": " +
                key.wfpflowid;
            } else {
              taskHeader += ", " + key.wfpflowid;
            }
          }
        });
      } else {
        //single task
        taskId = task.id;
        taskHeader =
          /*task.taskname+ " - " +*/ (process.env.REACT_APP_CUSTOM_TASKLABEL ||
            "Tarea") +
          ": " +
          task.wfpflowid;
        taskvars.map(function (key) {
          if (key.WFPFLOWID == task.wfpflowid) {
            let fieldValue = {
              name: key.VARNAME,
              label: headerObj[key.VARNAME]
                ? headerObj[key.VARNAME].VARLABEL
                : key.VARNAME.indexOf("comment_") >= 0
                ? getTranslateValue("comment") +
                  " - " +
                  key.VARNAME.replace("comment_", "")
                : key.VARNAME,
              value: key.VARVALUE,
              type: key.hidden ? "hidden" : "text",
              layout_id: 99999,
              // disabled : true,
              layout_sizeX: 6,
            };

            flowData.push(fieldValue);
            tempFlowValues[key.VARNAME] = key.VARVALUE;
            if (field) {
              if (key.VARNAME === field.VARNAME) {
                taskHeader =
                  /*task.taskname+ " - " +*/ field.VARLABEL +
                  ": " +
                  key.VARVALUE;
              }
            }
          }
        });
        formData = mergeFormData(formData, tempFlowValues);

        for (let i = 0; i < flowData.length; i++) {
          let row = flowData[i];
          let pos = formData.findIndex((item) => item.name === row.name);
          if (pos >= 0) {
            flowData.splice(i, 1);
            i--;
          }
        }

        //console.log("............", flowData, formData);
      }
      response = {
        flowData,
        commentary,
        formData,
        datapostload: resp.datapostload,
        datasubtitle: resp.datasubtitle,
        datatitle: resp.datatitle,
        taskHeader,
        taskName,
        isParent,
        taskId,
        wfpflowid,
        wfptaskid,
        officecode,
        task,
      };
      //      console.log("-.", response, tempFlowValues);
    } else {
      response = {
        formDataTask: [],
        formDialogStatus: false,
        status: false,
        error: "no form",
      };
    }
  } catch (Ex) {
    console.log("EX preparTaskForm>>>>>", Ex);
    response = {
      formDataTask: [],
      formDialogStatus: false,
      status: false,
      error: "err" + Ex,
    };
  } finally {
    return response;
  }
}

export function searchPhone(data) {
  for (let fielda in data) {
    // Verificar si la clave comienza con "field"
    if (fielda.startsWith("field")) {
      // Obtener el valor correspondiente a la clave
      let value = data[fielda];
      // Verificar si el valor comienza con "+591"
      if (typeof value === "string" && value.startsWith("+591")) {
        return value; // Devolver el valor si cumple la condición
      }
    }
  }
  return null; // Devolver null si no se encuentra ningún teléfono
}

export function searchMail(data) {
  for (let fielda in data) {
    // Verificar si la clave comienza con "field"
    if (fielda.startsWith("field")) {
      // Obtener el valor correspondiente a la clave
      let value = data[fielda];
      // Verificar si el valor comienza con "+591"
      if (
        typeof value === "string" &&
        value.indexOf("@") > 0 &&
        value.indexOf(".") > 0
      ) {
        return value; // Devolver el valor si cumple la condición
      }
    }
  }
  return null; // Devolver null si no se encuentra ningún teléfono
}

export function extractValuesFromBrackets(str, formData) {
  const regex = /\[([^\]]+)\]/g;

  const replaceFn = (match, p1) => {
    return formData[p1] && formData[p1].value !== undefined
      ? formData[p1].value
      : match;
  };

  // Reemplazar las subcadenas en el string original usando replaceFn
  return str.replace(regex, replaceFn);
}

export function calculateRows(text, textareaWidth) {
  let rows = Math.ceil(text.length / Math.floor(textareaWidth / 8));
  if (rows < 2) {
    rows = 2;
  }
  return rows;
}

export function evaluateString(dateString) {
  return !isNaN(new Date(dateString).getTime())
    ? `${String(new Date(dateString).getDate()).padStart(2, "0")}/${String(
        new Date(dateString).getMonth() + 1
      ).padStart(2, "0")}/${new Date(dateString).getFullYear()} ${String(
        new Date(dateString).getHours()
      ).padStart(2, "0")}:${String(new Date(dateString).getMinutes()).padStart(
        2,
        "0"
      )}`
    : dateString;
}
