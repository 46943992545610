import ApiClient from "../api/ApiClient";
import actionTypes from "../redux/action.types";
import * as Actions from "../redux/actions/process.actions";
import EndPoint from "../api/Endpoints";
import {
  prepareGetTasks,
  prepareForm,
  prepareComponentConfig,
  prepareExecuteTask,
  prepareGetHistoryFlows,
  prepareCustomPageConfig,
} from "../utils/Functions";
//import { toast } from "react-toastify";
export const processData = async (
  dispatch,
  componentName,
  action,
  data,
  header
) => {
  try {
     
    var response;
    if (action.indexOf("/") < 0) {
      response = await ApiClient.doPut(
        "/" + componentName + "/" + action,
        data
      );
    } else {
      response = await ApiClient.doPut(action, data);
    }

    if (response.status) {

      if(data.componentConfig?.customcomponent){
        componentName=data.componentConfig.componentName
      }
      
      const reloadData = await prepareComponentConfig(componentName);

      if (header) {
        reloadData.componentConfig["sigleComponentName"] = header
          .replace("Create new", "")
          .replace("Update", "");
      }

      //console.log("reloadData",reloadData)
      if (reloadData.status) {
        response["componentConfig"] = reloadData.componentConfig;
      }
      //console.log("response action", response, data)
      response["data"] = data;
      if (action == "create") {
        response["createdId"] = response.id;
      } else {
        response["createdId"] = 0;
      }
      dispatch({ type: actionTypes.PROCESS_SUCCESS, payload: response });
    } else {
      dispatch({ type: actionTypes.PROCESS_FAILED, payload: response });
    }
  } catch (error) {
    console.log(">>>>ERR:", error);
    dispatch({
      type: actionTypes.PROCESS_FAILED,
      payload: { message: "Internal Error:" + error },
    });
  }
};

export const fetchData = async (dispatch, componentName, action, data) => {
  try {
    const response = await ApiClient.doPost(
      "/" + componentName + "/" + action,
      data
    );
    if (response.status) {
      dispatch({ type: actionTypes.FETCH_SUCCESS, payload: response });
    } else {
      dispatch({ type: actionTypes.FETCH_FAILED, payload: response });
    }
  } catch (error) {
    console.log(">>>>ERR:", error);
    dispatch({
      type: actionTypes.FETCH_FAILED,
      payload: { message: "Internal Error:" + error },
    });
  }
};

export const FetchFiles = async (data) => {
  try {
    //console.log("list files", EndPoint.getFiles)
    const response = await ApiClient.doPost(EndPoint.getFiles, data);
    return response;
  } catch (e) {
    return { status: false, message: e.message, data: [] };
  }
};
export const createFileFolder = async (data) => {
  try {
    const response = await ApiClient.doPost(EndPoint.createFileFolder, data);
    return response;
  } catch (e) {
    return { status: false, message: e.message, data: [] };
  }
};
export const uploadFile = async (data, filename) => {
  //new
  try {
    var resp = await ApiClient.doPost(EndPoint.uploadFile, data, true);
    return resp;
  } catch (e) {
    return { status: false, message: e.message, data: [] };
  }
};
export const deleteFile = async (data) => {
  try {
    const response = await ApiClient.doPost(EndPoint.deleteFile, data);
    return response;
  } catch (e) {
    return { status: false, message: e.message, data: [] };
  }
};
//DEPRECATED
export const getFormData = async (dispatch, data, apiName) => {
  try {
    /*
    const validation = await prepareComponentConfig(data.code)  
    if(validation.componentConfig.writable ){
      */
    var response;

    if (data.componentConfig?.customcomponent) {
      apiName = "/customcomponents/get-single";
      console.log("si", apiName);
      response = await ApiClient.doPost(EndPoint["getTemplateForm"], {
        code: data.componentConfig.getAllDataApiData.formcode,
        component: "forms",
      });
      let id_field = data.componentConfig.getAllDataApiData.id_field;
      data["component"] = data.componentConfig.componentName;
      data["id_field"] = id_field;
    } else {
      response = await ApiClient.doPost(
        EndPoint["getDataByCodeLanguage"],
        data
      );
    }

    if (response.status) { 
      let formData = await prepareForm(
        response.data.CONFIG || response.data.DATA,
        data,
        apiName
      );
      dispatch({ type: actionTypes.FORM_LOADED, payload: formData });
    } else {
      dispatch({ type: actionTypes.FORM_ERROR });
    }
    /*
  }else{
    dispatch({ type: actionTypes.FORM_ERROR  });
  }*/
  } catch (error) {
    console.log(">>>>", error);
    dispatch({ type: actionTypes.FORM_ERROR });
  }
};

export const getCustomPageConfig = async (dispatch, data) => {
  try {
    const response = await prepareCustomPageConfig(data.pageid);

    if (response.status) {
      dispatch({
        type: actionTypes.CPAGE_LOADED,
        payload: { customPageConfig: response.customPageConfig },
      });
    } else {
      dispatch({ type: actionTypes.CPAGE_ERROR, payload: response });
    }
  } catch (error) {
    console.log("errr", error);
    dispatch({ type: actionTypes.CPAGE_ERROR, error: error });
  }
};

export const getComponentConfig = async (dispatch, data) => {
  try {
    const response = await prepareComponentConfig(data.code, data);

    if (response.status) {
      dispatch({
        type: actionTypes.COMPONENT_LOADED,
        payload: {
          componentConfig: response.componentConfig,
          formFields: response.formFields,
        },
      });
    } else {
      dispatch({ type: actionTypes.COMPONENT_ERROR, payload: response });
    }

    //dispatch({ type: actionTypes.COMPONENT_ERROR, payload: response });
  } catch (error) {
    console.log("errr", error);
    dispatch({ type: actionTypes.COMPONENT_ERROR, error: error });
  }
};

export const getHistoryFlows = async (dispatch, data) => {
  try {
    const response = await prepareGetHistoryFlows(data);
    //console.log("response.getTasks",data, response)
    if (response.status) {
      dispatch({ type: actionTypes.HISTORY_LOADED, payload: response });
    } else {
      dispatch({ type: actionTypes.HISTORY_FAILED, payload: response });
    }
  } catch (error) {
    console.log("errr", error);
    dispatch({ type: actionTypes.HISTORY_FAILED, error: error });
  }
};

export const getTasks = async (dispatch, data) => {
  try {
    const response = await prepareGetTasks(data);
    //console.log("response.getTasks",data, response)
    if (response.status) {
      dispatch({ type: actionTypes.TASKS_LOADED, payload: response });
    } else {
      dispatch({ type: actionTypes.TASKS_FAILED, payload: response });
    }
  } catch (error) {
    console.log("errr", error);
    dispatch({ type: actionTypes.TASKS_FAILED, error: error });
  }
};

export const executeTask = async (dispatch, data) => {
  try {
    const response = await prepareExecuteTask(data);
    if (response.status) {
      //console.log(response)
      dispatch({ type: actionTypes.PROCESS_SUCCESS, payload: response });
    } else {
      dispatch({ type: actionTypes.PROCESS_FAILED, payload: response });
    }
  } catch (error) {
    console.log(">>>>ERR:", error);
    dispatch({
      type: actionTypes.PROCESS_FAILED,
      payload: { message: "Internal Error:" + error },
    });
  }
};
