import React from "react";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import * as ProcessActions from "../../redux/actions/process.actions";
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Toolbar, Page, Sort, RowDD, Selection, Inject } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import ApiClient from "../../api/ApiClient";
import EndPoint from "../../api/Endpoints";
var varsGridInstance
var variablesDialogInstance;
const DialogFlowVasTemplate = (props) => {

  const toolbarOptions = ['Add', 'Delete', 'Edit', 'Cancel', 'Search' /*, { text: 'Save Vars', tooltipText: 'Save Vars', prefixIcon: 'e-check', id: 'savevars' } */];

  function dialogTemplate(props) {

    return (<DialogFormTemplate {...props} roles={roles} />);
  }

  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog', newRowPosition: 'Bottom', template: dialogTemplate };


  const editparams = { params: { popupHeight: '300px' } };
  const validationRule = { required: true };
  const numberRules = { required: true, number: true, };
  const pageSettings = { pageCount: 5 };
  const data = []

  const { defConfig, defLang, } = useSelector((state) => state.Login);

  const { componentConfig, processSuccess, processError, requesting, processMessage, initialValues, validationSchema, rows } = useSelector((state) => state.Process);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [id, setId] = React.useState(0);
  const [flowVars, setFlowVars] = React.useState(0);
  const [roles, setRoles] = React.useState([]);

  let { componentName } = useParams();


  React.useEffect(() => {

    //console.log("datos",props.roles)
    if (props.status && props.id > 0) {
      //console.log("cargamos");
      if (rows.length > 0) {
        //console.log("rows:", rows)
        setFlowVars(rows)
      }
      let _roles = []
      _roles.push({ USERGROUPID: "NO", USERGROUPNAME: "NO" })
      _roles.push({ USERGROUPID: "ALL", USERGROUPNAME: "ALL" })
      for (let i in props.roles) {
        _roles.push({ USERGROUPID: props.roles[i].USERGROUPNAME, USERGROUPNAME: props.roles[i].USERGROUPNAME })
      }

      console.log("final", _roles)
      setRoles(_roles)
    }

  }, [props, rows]);

  const sortingOptions = { columns: [{ field: 'VARNAME', direction: 'Ascending' }] };

  const submitForm = async (event) => {

    console.log("values", flowVars, varsGridInstance.dataSource)
    dispatch(ProcessActions.processData("flows", "set-flow-vars", { id: props.id, flowvars: varsGridInstance.dataSource }, props.header))
    //props.submitForm(values)
    variablesDialogInstance.hide()
  };

  const clickHandler = async (args) => {

    if (args.item.id === "savevars") {
      var selectedRows = varsGridInstance.dataSource.filter((it, index) => {
        if (it.VARNAME == args.data.VARNAME && it.SORTORDER == args.data.SORTORDER) {
          items.push(index);
          varsGridInstance.deleteRecord("VARNAME", it);

        }/*&& it.SORTNAME!= varsGridInstance.dataSource[delItem].SORTNAME*/
        varsGridInstance.selectRows(items);
      });
      varsGridInstance.addRecord({ SORTORDER: 34, VARNAME: "f", VARLABEL: "f", DEFVALUE: "f" })
    }


  };
  const actionComplete = (args) => {

    console.log(args)
    if (!args.item) {

      if (args.requestType === "save") {
        let delItem = -1
        for (let k = 0; k < varsGridInstance.dataSource.length; k++) {
          let key = varsGridInstance.dataSource[k]
          //console.log("comparando",  args.data.VARNAME == key.VARNAME)
          if (args.data.VARNAME == key.VARNAME) {
            delItem++
          }
        }
        if (delItem > 0) {
          let items = []
          var selectedRows = varsGridInstance.dataSource.filter((it, index) => {

            if (it.VARNAME == args.data.VARNAME) {
              items.push(index);
              varsGridInstance.deleteRecord("VARNAME", it);

            }/*&& it.SORTNAME!= varsGridInstance.dataSource[delItem].SORTNAME*/
            varsGridInstance.selectRows(items);
          });

          selectedRows = varsGridInstance.getSelectedRecords().filter(it => it.VARNAME == args.data.VARNAME /*&& it.SORTNAME!= varsGridInstance.dataSource[delItem].SORTNAME*/);
          selectedRows.forEach((element) => {
            //varsGridInstance.deleteRecord("VARNAME", element);  
          });


        }


      }

    } else {
      console.log("ac", args.item)
    }

  }

  const footerDialogFlow = () => {
    return (<div>
      <ButtonComponent
        className={"ml-10 e-primary"}
        onClick={submitForm}
        style={{ textTransform: "uppercase", fontWeight: "500" }}
      >   Save
      </ButtonComponent>
      <ButtonComponent
        className={"ml-10 e-btn   e-flat"}
        onClick={props.close}
        style={{ textTransform: "uppercase", fontWeight: "500" }}
      >
        Cancel
      </ButtonComponent>
    </div>);

  }


  return (
    <DialogComponent
      id="variablesDialogInstance"
      showCloseIcon={true}
      closeOnEscape={false}
      animationSettings={props.animationSettings}
      ref={(dialog) => (variablesDialogInstance = dialog)}
      isModal={true}
      open={props.open}
      close={props.close}
      header={"Edit Flow Variables"}
      footerTemplate={footerDialogFlow.bind(this)}


      height="100%"
      className="partial-form"
      position={{ X: 'right', Y: 'top' }}
      visible={props.status}
    >

      <div className='control-pane'>
        <div className='control-section'>
          <div className='col-12'>
            {flowVars.length > 0 &&
              <GridComponent
                toolbarClick={clickHandler.bind(this)}
                actionComplete={actionComplete.bind(this)}
                ref={(dialog) => (varsGridInstance = dialog)}
                dataSource={flowVars}
                pageSettings={pageSettings}
                toolbar={toolbarOptions}
                allowPaging={false}
                allowRowDragAndDrop={true}
                selectionSettings={{ type: 'Multiple' }}
                allowSorting={true}
                /*sortSettings={sortingOptions}*/
                editSettings={editSettings}>
                <ColumnsDirective>
                  {/** <ColumnDirective field='SORTORDER' headerText='Sort Order' width='120' validationRules={numberRules}  textAlign='Right' editType='numericedit' ></ColumnDirective> */}
                  <ColumnDirective field='VARNAME' headerText='Var Name' width='130' isPrimaryKey={true} validationRules={validationRule} ></ColumnDirective>
                  <ColumnDirective field='VARLABEL' headerText='Var Label' width='130' ></ColumnDirective>
                  <ColumnDirective field='EDITABLE' headerText='Is Editable' width='80'></ColumnDirective>
                  <ColumnDirective field='REMARKABLE' headerText='Highlight' width='80'></ColumnDirective>
                  <ColumnDirective field='HIDDEN' headerText='Hidden' width='80'></ColumnDirective>

                  <ColumnDirective field='DEFVALUE' headerText='Def Value' width='130'></ColumnDirective>


                </ColumnsDirective>
                <Inject services={[Page, Toolbar, Edit, Sort, RowDD, Selection]} />
              </GridComponent>
            }
          </div>

        </div>
      </div>
    </DialogComponent>
  );

};

function DialogFormTemplate(props) {

  let VARNAME;
  let VARLABEL;
  let DEFVALUE;
  const optValues = [{ text: "NO", value: "NO" }, { text: "YES", value: "YES" }]
  const [val, setval] = React.useState(props);
  console.log(props)


  function onChange(args) {

    let key = args.target.name;
    let value = args.target.value;
    setval(prevVal => ({ ...prevVal, [key]: value }));

  }

  let data = val;
  return (<div>
    <div className="form-row mt-2">
      <div className="form-group col-md-12">
        <div className="e-float-input e-control-wrapper">
          <input ref={input => VARNAME = input} id="VARNAME" name="VARNAME" type="text" disabled={!data.isAdd} value={data.VARNAME} onChange={onChange.bind(this)} />
          <span className="e-float-line"></span>
          <label className="e-float-text e-label-top"> Var Name</label>
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-12">
        <DropDownListComponent name="EDITABLE" onChange={onChange.bind(this)} id="EDITABLE" value={data.EDITABLE ? data.EDITABLE : "NO"} dataSource={data.roles} fields={{ text: 'USERGROUPNAME', value: 'USERGROUPID' }} placeholder="Editable" popupHeight='300px' floatLabelType='Always'></DropDownListComponent>
      </div>
    </div>

    <div className="form-row">
      <div className="form-group col-md-12">
        <DropDownListComponent name="REMARKABLE" onChange={onChange.bind(this)} id="REMARKABLE" value={data.REMARKABLE ? data.REMARKABLE : "NO"} dataSource={optValues} fields={{ text: 'text', value: 'value' }} placeholder="Highlight" popupHeight='300px' floatLabelType='Always'></DropDownListComponent>
      </div>
    </div>

    <div className="form-row">
      <div className="form-group col-md-12">
        <DropDownListComponent name="HIDDEN" onChange={onChange.bind(this)} id="HIDDEN" value={data.HIDDEN ? data.HIDDEN : "NO"} dataSource={optValues} fields={{ text: 'text', value: 'value' }} placeholder="Hidden" popupHeight='300px' floatLabelType='Always'></DropDownListComponent>
      </div>
    </div>

    

    <div className="form-row">
      <div className="form-group col-md-12">
        <div className="e-float-input e-control-wrapper">
          <input ref={input => VARLABEL = input} value={data.VARLABEL} id="VARLABEL" name="VARLABEL" type="text" onChange={onChange.bind(this)} />
          <span className="e-float-line"></span>
          <label className="e-float-text e-label-top">Var Label</label>
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-12">
        <div className="e-float-input e-control-wrapper">
          <input ref={input => DEFVALUE = input} value={data.DEFVALUE} id="DEFVALUE" name="DEFVALUE" type="text" onChange={onChange.bind(this)} />
          <span className="e-float-line"></span>
          <label className="e-float-text e-label-top">Def Value</label>
        </div>
      </div>
    </div>



  </div>);
}

DialogFlowVasTemplate.propTypes = {};

export default DialogFlowVasTemplate;
