import { FormValidator } from "@syncfusion/ej2-inputs";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import Typography from "../customdesign/Typography";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

class CompTreeview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      treeObj: {},
    };
  }

  componentDidMount() {
    let data = this.props.dataSource;
    var updatedData = data
    if (this.props.userid!="0"){
      updatedData = data.filter(item => item.id !== 22);
    }
    this.setState({ ...this.state, data: updatedData });
    //  document.removeEventListener('click', this.checkClick);
    //	document.addEventListener("click",this.checkClick );
  }

  componentWillUnmount() {
    //	document.removeEventListener('click', this.checkClick);
  }

  nodeTemplate(data) {
    return (
      <div>
        <div className="treeviewdiv">
          {data.type ? (
            data.type == "title" ? (
              <div className="titlecontent">
                <span className="treeName">{data.label?data.label:data.name}</span>
              </div>
            ) : data.type == "root" ? (
              <div className="titlerootcontent">
                {data.name === "Flows" && (
                  <span className="e-flows-gray"> </span>
                )}

                {data.name === "Administration" && (
                  <span className="e-admin-gray"> </span>
                )}
                {data.name === "Pages" && (
                  <span className="e-pages-gray"> </span>
                )}

                {data.name === "Developer Center" && (
                  <span className="e-icons e-code-view e-code-icon"> </span>
                )}

                <span className="treeName">{data.label?data.label:data.name}</span>
                {data.name === "!  Flows" && (
                  <ButtonComponent
                    id="refresh-wfl-menu"
                    cssClass="e-flat e-primary e-menu-icon-refresh"
                    iconCss="e-btn-icon e-icons e-refresh "
                    onClick={() => {
                      console.log("fff");
                    }}
                  ></ButtonComponent>
                )}
              </div>
            ) : (
              <div className="textcontent">
                <span className={"e-flows"}> - </span>
                <span className="treeName">{data.label?data.label:data.name}</span>
              </div>
            )
          ) : (
            <>
              <div className="textcontent">
                <span className="treeName">
                  { (data.label?data.label:data.name) .length > 26
                    ? (data.label?data.label:data.name).substr(0, 23) + "..."
                    : (data.label?data.label:data.name)}
                </span>
              </div>
              {data.actives >= 0 && (
                <div className="countcontainer">
                  <span className="treeCount e-badge e-badge-secondary">
                    {data.actives}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  nodeClicked = async (args) => {
    let checkedNode = [args.node];
    if (args.event.target.classList.contains("e-fullrow")) {
      let getNodeDetails = this.state.treeObj.getNode(args.node);
      this.props.nodeClicked(getNodeDetails.id);
    }
  };
  render() {
    return (
      <div className="control-pane">
        <div className="control-section">
          <div className="tree-control-wrapper">
            {/* Render the TreeView using template option */}
            <TreeViewComponent
              ref={(treeview) => {
                this.state.treeObj = treeview;
              }}
              fields={{
                dataSource: this.state.data,
                id: "id",
                parentID: "pid",
                text: "name",
                hasChildren: "hasChild",
                tooltip: "tooltip"
              }}
              nodeClicked={this.nodeClicked}
              nodeTemplate={
                this.props.nodeTemplate
                  ? this.props.nodeTemplate
                  : this.nodeTemplate
              }
              cssClass={this.props.cssClass}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default CompTreeview;
