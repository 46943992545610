import * as React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ChipListComponent } from "@syncfusion/ej2-react-buttons";
import Typography from "../customdesign/Typography";
import {
  PdfStandardFont,
  PdfFontFamily,
  PdfFontStyle,
} from "@syncfusion/ej2-pdf-export";
import DialogSetHeaderColumns from "../dialog/DialogSetHeaderColumns";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Filter,
  Sort,
  Edit,
  Resize,
  Toolbar,
  Aggregate,
  Page,
  DetailRow,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import {
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
} from "@syncfusion/ej2-react-grids";
import Utils from "../../utils/Utils";
import {
  prepareForm,
  getComponentReport,
  getCookie,
} from "../../utils/Functions";
import GenericForm from "../../components/forms/GenericForm";
import * as ProcessActions from "../../redux/actions/process.actions";
import { Browser } from "@syncfusion/ej2-base";
import Loading from "../../components/customdesign/Loading";

import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Legend,
  Category,
  StackingColumnSeries,
  Tooltip,
  DataLabel,
  Highlight,
} from "@syncfusion/ej2-react-charts";

import {
  Chart3DComponent,
  Chart3DSeriesCollectionDirective,
  Chart3DSeriesDirective,
  Legend3D,
  Category3D,
  StackingColumnSeries3D,
  Tooltip3D,
  Highlight3D,
} from "@syncfusion/ej2-react-charts";
let gridInstance = {};

let data = [
  { x: "2013", y: 9628912, y1: 4298390, y2: 2842133, y3: 2006366 },

  { x: "2014", y: 9609326, y1: 4513769, y2: 3016710, y3: 2165566 },
  { x: "2015", y: 7485587, y1: 4543838, y2: 3034081, y3: 2279503 },
  { x: "2016", y: 7793066, y1: 4999266, y2: 2945295, y3: 2359756 },
  { x: "2017", y: 6856880, y1: 5235842, y2: 3302336, y3: 2505741 },
];

export let data1 = [
  { x: "GBR", y: 27, toolTipMappingName: "Great Britain" },
  { x: "CHN", y: 26, toolTipMappingName: "China" },
  { x: "AUS", y: 8, toolTipMappingName: "Australia" },
  { x: "RUS", y: 19, toolTipMappingName: "Russia" },
  { x: "GER", y: 17, toolTipMappingName: "Germany" },
  { x: "UA", y: 2, toolTipMappingName: "Ukraine" },
  { x: "ES", y: 7, toolTipMappingName: "Spain" },
  { x: "UZB", y: 4, toolTipMappingName: "Uzbekistan" },
  { x: "JPN", y: 12, toolTipMappingName: "Japan" },
  { x: "NL", y: 8, toolTipMappingName: "NetherLand" },
  { x: "USA", y: 46, toolTipMappingName: "United States" },
];
export let data2 = [
  { x: "GBR", y: 23, toolTipMappingName: "Great Britain" },
  { x: "CHN", y: 18, toolTipMappingName: "China" },
  { x: "AUS", y: 11, toolTipMappingName: "Australia" },
  { x: "RUS", y: 17, toolTipMappingName: "Russia" },
  { x: "GER", y: 10, toolTipMappingName: "Germany" },
  { x: "UA", y: 5, toolTipMappingName: "Ukraine" },
  { x: "ES", y: 4, toolTipMappingName: "Spain" },
  { x: "UZB", y: 2, toolTipMappingName: "Uzbekistan" },
  { x: "JPN", y: 8, toolTipMappingName: "Japan" },
  { x: "NL", y: 7, toolTipMappingName: "NetherLand" },
  { x: "USA", y: 37, toolTipMappingName: "United States" },
];
export let data3 = [
  { x: "GBR", y: 17, toolTipMappingName: "Great Britain" },
  { x: "CHN", y: 26, toolTipMappingName: "China" },
  { x: "AUS", y: 10, toolTipMappingName: "Australia" },
  { x: "RUS", y: 20, toolTipMappingName: "Russia" },
  { x: "GER", y: 15, toolTipMappingName: "Germany" },
  { x: "UA", y: 24, toolTipMappingName: "Ukraine" },
  { x: "ES", y: 6, toolTipMappingName: "Spain" },
  { x: "UZB", y: 7, toolTipMappingName: "Uzbekistan" },
  { x: "JPN", y: 8, toolTipMappingName: "Japan" },
  { x: "NL", y: 4, toolTipMappingName: "NetherLand" },
  { x: "USA", y: 38, toolTipMappingName: "United States" },
];

const animationSettings = { effect: "SlideRight" };

const Report = (props) => {
  const { defConfig, params, defLang, activeForm } = useSelector(
    (state) => state.Login
  );

  const dispatch = useDispatch();
  const [formFields, setFormFields] = React.useState([]);
  const [values, setValues] = React.useState({});
  const [historyTasks, setHistoryTasks] = React.useState({});
  const [historyFlows, setHistoryFlows] = React.useState({});
  const [currentParams, setCurrentParams] = React.useState({ initial: true });
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);

  const [header, setHeader] = React.useState([]);
  const [dashboards, setDashboards] = React.useState([]);

  const [excludedColumns, setExcludedColumns] = React.useState([]);
  const [actorUserDialog, setActorUserDialog] = React.useState(false);
  const [pageId, setPageId] = React.useState(0);

  const filterOptions = { type: "Excel" };
  const renderingMode = "Vertical";

  const toolbarOptions = [
    {
      text: Browser.isDevice ? "" : "Refresh",
      prefixIcon: "e-icons e-refresh",
      tooltipText: "Refresh",
      id: "refresh",
    },
    "ExcelExport",
    "PdfExport",

    "Search",
  ];

  const onChange = async (event) => {
    let _values = values;
    _values[event.target.name] = event.target.value;
    setValues(_values);
  };

  const loadForm = async (form) => {
    if (formFields.length === 0) {
      let _formFields = await prepareForm(form);
      setFormFields(_formFields);
      let _values = {};
      _formFields.map(function (key, item) {
        _values[key["name"]] = key["value"] ? key["value"] : "";
      });
      setValues(_values);
    }
  };

  const loadDashboards = async (_dashboards) => {
    if (dashboards.length === 0) {
      //console.log("loadDashboards", _dashboards);
      setDashboards(_dashboards);
    }
  };

  React.useEffect(() => {
    loadForm(props.filter);
    loadDashboards(props.dashboards);

    const currentUrl = window.location.pathname;
    const segments = currentUrl.split("/");
    let _pageId = segments[segments.length - 1];
    setPageId(_pageId);

    let _filterdCols = getCookie(
      process.env.REACT_APP_NAME + "_reportPage_" + _pageId
    );
    let filteredColes = [];
    if (_filterdCols) {
      setExcludedColumns(JSON.parse(_filterdCols));
    }

    if (props.filter.length === 0) {
      setDashboards(props.dashboards);
      getReport();
    }
  }, [props]);

  const getReport = async (params) => {
    setLoading(true);

    const currentUrl = window.location.pathname;
    const segments = currentUrl.split("/");
    let _pageId = segments[segments.length - 1];

    let response = await getComponentReport(values, props.filter, _pageId);
    //console.log("response", response);
    setDashboards(response);
    setLoading(false);
    setLoading2(true);
    setTimeout(
      function () {
        setLoading2(false);
      }.bind(this),
      10
    );
  };

  const contextToolbarMenuTaskClick = async (args) => {
    //console.log("args.item.id ", args.item.id);
    if (args.item.id == "refresh") {
      getReport();
    } else if (args.item.id.indexOf("excelexport") >= 0) {
      let component = args.item.id.replace("_excelexport", "");
      console.log("component", component);
      gridInstance[component].excelExport(
        getExcelExportProperties(props.title)
      );
    } else if (args.item.id.indexOf("pdfexport") >= 0) {
      let component = args.item.id.replace("_pdfexport", "");
      console.log("component", component);
      gridInstance[component].pdfExport(getPdfExportProperties(props.title));
    }

    setLoading2(true);
    setTimeout(
      function () {
        setLoading2(false);
      }.bind(this),
      10
    );
  };

  function load(args) {
    let selectedTheme = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/contrast/i, "Contrast");
  }

  function getExcelExportProperties(name) {
    //console.log("na", (name).replaceAll(" ", "_"))
    let filename = name.replaceAll(" ", "_") + ".xlsx";
    return {
      fileName: filename,
    };
  }

  function getPdfExportProperties(name) {
    //console.log("na", (name).replaceAll(" ", "_"))
    let filename = name.replaceAll(" ", "_") + ".pdf";

    return {
      filename: filename,
      pageOrientation: "Landscape",
      pageSize: "Letter",

      theme: {
        caption: {
          font: new PdfStandardFont(PdfFontFamily.TimesRoman, 9),
          fontColor: "#0f001c",
          bold: true,
        },
        record: {
          font: new PdfStandardFont(PdfFontFamily.TimesRoman, 9),
          fontColor: "#0f001c",
          bold: true,
        },
      },
    };
  }

  const cellTemplate = (props, columnName, columnText) => {
    var value = props[columnName];
    if (columnText.indexOf("[%]") >= 0) {
      value = (value * 100).toFixed(2) + "%";
    }
    //console.log("columnName",columnName,columnText )
    if (value) {
      if (value.toString().startsWith("FLAG_RED")) {
        return (
          <ChipListComponent
            cssClass="e-danger"
            text={value.replace("FLAG_RED", "").trim()}
          ></ChipListComponent>
        );
      } else if (value.toString().startsWith("FLAG_YELLOW")) {
        return (
          <ChipListComponent
            cssClass="e-warning"
            text={value.replace("FLAG_YELLOW", "").trim()}
          ></ChipListComponent>
        );
      } else {
        if (columnName.indexOf("URL_") >= 0) {
          let url = props[columnName];
          let name = props[columnName];
          if (url) {
            if (url.indexOf("___") >= 0) {
              let _url = url.split("___");
              url = _url[0];
              name = _url[1];
            }
          }
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          );
        } else {
          return value;
        }
      }
    } else {
      return value;
    }
  };

  //DEPRECATED
  const renderLink = (props) => {
    const urlField = Object.keys(props).find((key) => key.startsWith("URL_"));
    if (urlField) {
      let url = props[urlField];
      let name = props[urlField];
      if (url) {
        if (url.indexOf("___") >= 0) {
          let _url = url.split("___");
          url = _url[0];
          name = _url[1];
        }
      }

      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      );
    }

    return null; // Retornar null si no se encuentra ningún campo que empiece con "URL_"
  };

  const updateColumns = (columns) => {
    setLoading(true);
    //console.log("updating", columns);
    setTimeout(
      function () {
        setExcludedColumns(columns);
        setLoading(false);
      }.bind(this),
      500
    );
  };

  return (
    <div style={{ overflow: "auto", height: "calc(100vh - 110px)" }}>
      {loading && <Loading timer={4000} contained={true} />}
      <div className="row no-padding mb-1">
        <div className="no-padding col-12">
          {props.title && (
            <Typography
              variant="h3"
              mt={1}
              style={{
                borderBottom: "3px solid #4c38c9",
                lineHeight: "5rem",
              }}
            >
              {props.title}
            </Typography>
          )}
        </div>
      </div>
      <div className="row no-padding mb-1">
        {formFields.length > 0 && (
          <div className="col-sm-12 col-md-12 col-md-4 col-lg-3 col-xl-2 min-padding">
            <div className="filter-container">
              {formFields.length > 0 && (
                <>
                  {!loading2 && dashboards.length === 1 ? (
                    <GenericForm
                      data={formFields}
                      onChange={onChange}
                      datapostload={props.datapostload || ""}
                      buttons={[
                        {
                          size: "col-xs-12 col-md-12 col-lg-6 col-xl-6 text-left",
                          label: defConfig[defLang].setHeaderColumns,
                          name: "Columns",
                          className: "e-info",
                          action: () => {
                            setActorUserDialog(true);
                          },
                        },

                        {
                          size: "col-xs-12 col-md-12 col-lg-6 col-xl-6 text-right",
                          label: defConfig[defLang].searchlabel,
                          name: "Search",
                          action: getReport,
                        },
                      ]}
                    />
                  ) : (
                    <GenericForm
                      data={formFields}
                      onChange={onChange}
                      datapostload={props.datapostload || ""}
                      buttons={[
                        {
                          size: "col-xs-12 col-md-12 col-lg-12 col-xl-12 text-right",
                          label: defConfig[defLang].searchlabel,
                          name: "Search",
                          action: getReport,
                        },
                      ]}
                    />
                  )}
                  {pageId > 0 && (
                    <DialogSetHeaderColumns
                      id={pageId}
                      status={actorUserDialog}
                      animationSettings={animationSettings}
                      columns={dashboards[0].header}
                      updateColumns={updateColumns}
                      close={() => {
                        setActorUserDialog(false);
                      }}
                      open={() => {
                        setActorUserDialog(true);
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {!loading && (
          <div
            className={
              formFields.length === 0
                ? "col-sm-12 min-padding"
                : "col-sm-12 col-md-12 col-md-8 col-lg-9 col-xl-10 min-padding"
            }
          > 
            <div style={{ padding: Browser.isDevice ? "5px" : "0" }}>
              {dashboards.length > 0 && (
                <>
                  {dashboards.map((key, index) => {
                    return key.type === "grid" ? (
                      <>
                        <h3>{key.title} </h3>
                        <GridComponent
                          id={"component" + index}
                          allowResizing={true}
                          cssClass="table-report"
                          filterSettings={filterOptions}
                          allowPdfExport={true}
                          allowExcelExport={true}
                          ref={(grid) =>
                            (gridInstance["component" + index] = grid)
                          }
                          toolbarClick={contextToolbarMenuTaskClick}
                          toolbar={toolbarOptions}
                          dataSource={key.data}
                          allowFiltering={true}
                          allowSorting={true}
                          allowPaging={true}
                          pageSettings={{
                            pageCount: 3,
                            pageSize: 10,
                            pageSizes: true,
                          }}
                        >
                          <ColumnsDirective>
                            {key.header &&
                              key.header.map((b, index) => {
                                if (excludedColumns.indexOf(b.value) < 0) {
                                  return (
                                    <ColumnDirective
                                      field={b.value}
                                      headerText={b.text.replace("URL_", "")}
                                      width={b.width || 120}
                                      template={(props) =>
                                        cellTemplate(props, b.value, b.text)
                                      }
                                    />
                                  );
                                }
                              })}
                          </ColumnsDirective>
                          <Inject
                            services={[
                              Filter,
                              Resize,
                              Sort,
                              DetailRow,
                              ExcelExport,
                              PdfExport,
                              Toolbar,
                              Page,
                            ]}
                          />
                        </GridComponent>
                      </>
                    ) : (
                      <>
                        <Chart3DComponent
                          id={"charts" + index}
                          style={{ textAlign: "center" }}
                          legendSettings={{ enableHighlight: true }}
                          primaryXAxis={{
                            valueType: "Category",
                            labelPlacement: "BetweenTicks",
                          }}
                          primaryYAxis={{
                            rangePadding: "None",
                            interval: Browser.isDevice ? 25 : 20,
                          }}
                          wallColor="transparent"
                          height="400"
                          enableRotation={true}
                          rotation={7}
                          tilt={10}
                          depth={100}
                          width={Browser.isDevice ? "100%" : "75%"}
                          load={load.bind(this)}
                          title={key.title}
                          tooltip={{
                            enable: true,
                            format:
                              "${point.x} : <b>${point.y} (${point.percentage}%)</b>",
                          }}
                        >
                          <Inject
                            services={[
                              StackingColumnSeries3D,
                              Legend3D,
                              Tooltip3D,
                              Category3D,
                              Highlight3D,
                            ]}
                          />
                          <Chart3DSeriesCollectionDirective>
                            {key.header.yvalue && key.header.yvalue !== "" && (
                              <Chart3DSeriesDirective
                                dataSource={key.data}
                                xName="x"
                                yName="y"
                                name={key.header.yvalue}
                                columnWidth={0.5}
                                type="StackingColumn100"
                              />
                            )}

                            {key.header.yvalue2 &&
                              key.header.yvalue2 !== "" && (
                                <Chart3DSeriesDirective
                                  dataSource={key.data}
                                  xName="x"
                                  yName="y2"
                                  name={key.header.yvalue2}
                                  columnWidth={0.5}
                                  type="StackingColumn100"
                                />
                              )}

                            {key.header.yvalue3 &&
                              key.header.yvalue3 !== "" && (
                                <Chart3DSeriesDirective
                                  dataSource={key.data}
                                  xName="x"
                                  yName="y3"
                                  name={key.header.yvalue3}
                                  columnWidth={0.5}
                                  type="StackingColumn100"
                                />
                              )}
                          </Chart3DSeriesCollectionDirective>
                        </Chart3DComponent>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Report.propTypes = {
  //  window: ListData.func,
};

export default Report;
