import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../redux/actions/process.actions";
import LoadingForm from "../customdesign/LoadingForm";
import GenericForm from "../forms/GenericForm";
import { DialogComponent } from '@syncfusion/ej2-react-popups';


var listDialogForm;

const DialogTemplateItem = (props) => {

  const { defConfig, defLang, } = useSelector((state) => state.Login);
  const { componentConfig, processSuccess, processError, requesting, processMessage, formFields, initialValues, validationSchema } = useSelector((state) => state.Process);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({})

  let { componentName } = useParams();

  React.useEffect(() => {
    //console.log("formFields", formFields)
    formFields.map(function (key, item) {
      values[key["name"]] = key["value"]
    });



  }, [props, formFields]);

  const submitForm = async () => {

    values["id"] = props.id
    let action = ""
    if (props.action === "add") {
      action = "create"
    } else if (props.action === "edit") {
      action = "update"
    }
    setValues(values)

    if (  componentConfig.customcomponent){
      values["componentConfig"] = componentConfig
      dispatch(ProcessActions.processData("customcomponents", action, values, props.header))
    }else{
      dispatch(ProcessActions.processData(componentName, action, values, props.header))
    }
    
    props.close()
  };

  const onChange = async (event) => {
    let _values = values 
    _values[event.target.name] = event.target.value
    setValues(_values)
  };

  const exportForm = async () => {
    values["id"] = props.id
    let action = ""
    if (props.action === "add") {
      action = "create"
    } else if (props.action === "edit") {
      action = "update"
    }
    setValues(values)

    const fileData = JSON.stringify(values);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "template.json";
    link.href = url;
    link.click();

    //console.log(values)
    //props.close()
  };

  const saveTemplate = async () => {
    values["id"] = props.id
    let action = ""
    if (props.action === "add") {
      action = "create"
    } else if (props.action === "edit") {
      action = "update"
    }
    setValues(values)


    props.openTemplate(values)
  };



  return (
    <>


      <DialogComponent
        id={"listDialogForm" + props.componentName}
        showCloseIcon={true}
        animationSettings={props.animationSettings}
        closeOnEscape={false}
        ref={(dialog) => (listDialogForm = dialog)}
        isModal={true}
        open={props.open}
        close={props.close}
        cssClass="full-dialog"
        header={props.header}
        buttons={props.hideButton ? [{
          click: () => {
            props.close();
            //listDialogForm.hide();
          },
          buttonModel: {
            content: 'Close',
            isSecondary: true,
          },
        },] : requesting ? [{
          click: () => {
            props.close();
            //listDialogForm.hide();
          },
          buttonModel: {
            content: 'Back',
            isSecondary: true,
          },
        }] : props.header.indexOf("Task") > 0 ?
          [
            {
              click: () => {
                submitForm();
              },
              buttonModel: {
                content: 'Save',
                isPrimary: true,
              },
            },
            {
              click: () => {
                exportForm();
              },
              buttonModel: {
                content: 'Export',
                cssClass: "e-success",
                isSuccess: true,
              },
            },
            {
              click: () => {
                saveTemplate();
              },
              buttonModel: {
                content: 'Save as template',
                isSuccess: true,
              },
            },
            {
              click: () => {
                props.close();
                //listDialogForm.hide();
              },
              buttonModel: {
                content: 'Back',
                cssClass: "bordered",
                isSecondary: true,
              },
            },
          ]
          :
          [
            {
              click: () => {
                submitForm();
              },
              buttonModel: {
                content:  defConfig[defLang].savelabel,
                isPrimary: true,
              },
            },
            {
              click: () => {
                props.close();
                //listDialogForm.hide();
              },
              buttonModel: {
                content: defConfig[defLang].cancellabel,
                cssClass: "bordered",
                isSecondary: true,
              },
            },
          ]


        }
        width={window.innerWidth > 1650 ? "45%" : window.innerWidth > 1240 ? "55%" : "75%"}
        height="auto"
        position={{ X: 'center', Y: 'center' }}
        visible={props.status}
      >
        <div className='control-pane pt-2'>
          <div>
            <div className="form-container">

              {requesting &&
                <LoadingForm />
              }

              {props.customFields ?
                <>
                  {props.updateForm &&
                    <GenericForm
                      data={props.customFields}
                      datapostload={props.datapostload}
                      onChange={onChange}
                      buttons={[]}
                    />
                  }
                </>

                :
                <>
                  {!requesting &&
                    <GenericForm
                      data={formFields}
                      onChange={onChange}
                      buttons={[]}
                    />
                  }
                </>
              }


            </div>
          </div>
        </div>
      </DialogComponent>


    </>
  );
};
DialogTemplateItem.propTypes = {};

export default DialogTemplateItem;
