import * as React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import GenericForm from "../../components/forms/GenericForm";
import LoadingForm from "../../components/forms/LoadingForm";
import DialogReports from "../../components/dialog/DialogReports";
import Loading from "../../components/customdesign/Loading";
import {
  UploaderComponent,
  FilesDirective,
  UploadedFilesDirective,
} from "@syncfusion/ej2-react-inputs";
import Report from "../../components/report/Report";
import {
  validateForm,
  find,
  clearformData,
  getCookie,
} from "../../utils/Functions";
import * as ProcessActions from "../../redux/actions/process.actions";
import ApiClient from "../../api/ApiClient";
import EndPoint from "../../api/Endpoints";
let wHeight = window.innerHeight;
let uploadObj;
var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
const animationSettings = { effect: "SlideRight" };
const ManageCustomPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { defConfig, params, defLang, activeForm } = useSelector(
    (state) => state.Login
  );
  const [formValidation, setFormValidation] = React.useState([]);
  const { customPageConfig, requesting } = useSelector(
    (state) => state.Process
  );
  const [finalFormFields, setFinalFormFields] = React.useState([]);
  const [actions, setActions] = React.useState([]);
  const [pageType, setPageType] = React.useState("forms");
  const [loading, setLoading] = React.useState(false);
  const [loadingPage, setLoadingPage] = React.useState(false);

  const [values, setValues] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [datapostload, setDatapostload] = React.useState("");

  
  const [dashboards, setDashboards] = React.useState([]);
  const [reportDialog, setReportDialog] = React.useState(false);
  const [massiveLoadComponent, setMassiveLoadComponent] = React.useState("");  
  
  const [logsformFields, setLogsformFields] =React.useState( [
    {
      name: "id",
      label: "Load Id",
      type: "text",
      action: "",
      variant: "",
      value: "",
      values: [],
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 1,
      layout_id: "1_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 0,
      layout_col: 0,
      layout_sizeX: 12,
      layout_sizeY: 2,
    },
  ]);

  const reportInfo = {
    header: [
      {
        field: "LOADID",
        label: "Id",
      },
      {
        field: "STATUS",
        label: defConfig[defLang].statuslabel,
      },
    ],
    apiName: "/files/get-bulk-data",
  };

  let c = 1;
  for (let i = 0; i < 30; i++) {
    reportInfo.header.push({
      field: "FIELD" + i,
      label: defConfig[defLang].fieldlabel + c,
    });
    c++;
  }

  let { pageid } = useParams();

  const onChange = async (event) => {
    values[event.target.name] = event.target.value;
    let pos = find(finalFormFields, event.target.name, "name");
    finalFormFields[pos].value = event.target.value;
  };

  const submitForm = async (event) => {
    let validResponse = await validateForm(finalFormFields, values);
    // console.log("eve", event.target.name, values, validResponse);

    setLoading(true);
    // console.log("val",validResponse.messages)
    setTimeout(
      async function () {
        setFormValidation(validResponse.messages);
        if (validResponse.messages.length === 0) {
          const pos = actions.findIndex(
            (item) => item.OPTIONCODE === event.target.name
          );
          let action = actions[pos].ACTION;
          let method = actions[pos].METHOD;

          if (EndPoint[action]) {
            var response = await ApiClient.doPut(EndPoint[action], values);

            dispatch(ProcessActions.setMessage(response));
            if (response.status) {
              clearformData(finalFormFields);
              //console.log(_finalFormFields)
            }
          } else {
            var response = await ApiClient.doPut(action, values);
            dispatch(ProcessActions.setMessage(response));
            if (response.status) {
              clearformData(finalFormFields);
              //console.log(_finalFormFields)
            }
          }
        }
        setLoading(false);
      }.bind(this),
      50
    );
  };

  const massiveLoad = async (event) => {
    console.log("eve", event.target.name, uploadObj.filesData);

    setLoadingPage(true);
    const formData = new FormData();
    let uploadedFiles = uploadObj.filesData;
    formData.append("filename", uploadedFiles[0].rawFile);
    formData.append("componentcode", massiveLoadComponent);
    const response = await ApiClient.doPost(EndPoint["bulkData"], formData);
    if (response.status) {
      let _logsformFields = logsformFields
      _logsformFields[0]["value"] = response.id;
      setLogsformFields(_logsformFields)
      
    }
    console.log(response, logsformFields);
    setLoadingPage(false);
  };

  const loadReport = async (customPageConfig) => {
    //console.log("entering");

    let _dashboards = [];
    for (let i in customPageConfig.data) {
      let dashB = customPageConfig.data[i];
      let dash = {};
      if (Array.isArray(dashB.values)) {
        dash = {
          header: dashB.values.options,
          component: dashB.component,
          title: dashB.title,
          type: dashB.type,
          data: [],
        };
      } else {
        if (dashB.values.datatype === "static") {
          dash = {
            header: dashB.values.options,
            component: dashB.component,
            title: dashB.title,
            type: dashB.type,
            data: [],
          };
        }
      }
      _dashboards.push(dash);
    }
    setDashboards(_dashboards);
    setFilter(customPageConfig.filter);
    setDatapostload(customPageConfig.datapostload);

    setActions([]);
  };

  React.useEffect(() => {
    if (!customPageConfig || customPageConfig.initial) {
      dispatch(ProcessActions.getCustomPageConfig(pageid, defLang));
    } else {
      if (requesting) {
        setFinalFormFields([]);
        setDashboards([]);
        setFilter({});
        setValues({});
      } else {
        /*
        dispatch(ProcessActions.setProcessing());
        setTimeout(
          async function () {
            dispatch(ProcessActions.setProcessed());
          }.bind(this),
          2500
        );
        */
        if (customPageConfig.type === "forms") {
          setFinalFormFields(customPageConfig.data);
          customPageConfig.data.map(function (key, item) {
            values[key["name"]] = key["value"];
          });
        } else {
          loadReport(customPageConfig);
        }

        setPageType(customPageConfig.type);

        if (Array.isArray(customPageConfig.action)) {
          customPageConfig.action.map(function (a) {
            if (
              a.ACTION.indexOf("customcomponents") >= 0 &&
              customPageConfig.action.length === 1
            ) {
              let component = a.ACTION.substring(a.ACTION.lastIndexOf("/") + 1);
              console.log("component", component);
              setMassiveLoadComponent(component);
            }
          });
          setActions(customPageConfig.action);
        }
      }
    }
  }, [customPageConfig, requesting]);

  const reportDialogClose = () => {
    setReportDialog(false);
  };
  const reportDialogOpen = () => {
    setReportDialog(true);
  };

  return (
    <>
      {loadingPage && <Loading />}

      

      { pageType === "forms" && finalFormFields.length > 0 && massiveLoadComponent !== "" && (
        <>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-padding">
              <div id="control_panel_loads" className="mt-1-mobile mb-2">
                <h5>{defConfig[defLang].massiveloadtitle} </h5>
                <form id="formBulk" method="post">
                  <UploaderComponent
                    id="file"
                    type="file"
                    ref={(scope) => {
                      uploadObj = scope;
                    }}
                    autoUpload={false}
                    multiple={false}
                    allowedExtensions={".xls, .xlsx"}
                  ></UploaderComponent>
                </form>
                <div className="items-right">
                  <ButtonComponent
                    className={"e-secondary mr-1"}
                    onClick={reportDialogOpen}
                    style={{
                      fontWeight: "500",
                      position: "relative",
                      top: 8,
                      marginRight: 2,
                      textTransform: "capitalize",
                    }}
                  >
                    {defConfig[defLang].showloadslabel}
                  </ButtonComponent>

                  <ButtonComponent
                    className={"e-success"}
                    onClick={massiveLoad}
                    style={{
                      fontWeight: "500",
                      position: "relative",
                      top: 8,
                      marginRight: 2,
                      textTransform: "capitalize",
                    }}
                  >
                    {defConfig[defLang].uploadfilelabel}
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </div>
          {loadingPage ? (
            "cargango"
          ) : (
            <DialogReports
              header={defConfig[defLang].statusloadtitle}
              status={reportDialog}
              reportInfo={reportInfo}
              formFields={logsformFields}
              filterValue={massiveLoadComponent}
              animationSettings={animationSettings}
              close={reportDialogClose}
              open={reportDialogOpen}
            />
          )}
        </>
      )}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-padding">
          <div id="control_panel_dash" className="mt-1-mobile">
            {pageType === "forms" ? (
              <div className="form-container ">
                {finalFormFields.length > 0 && (
                  <>
                    {!loading ? (
                      <GenericForm
                        iFrameHeight={wHeight - 158}
                        datatitle={
                          customPageConfig.datatitle
                            ? customPageConfig.datatitle
                            : ""
                        }
                        data={finalFormFields}
                        noExternalButon={true}
                        formValidation={formValidation}
                        onChange={onChange}
                        buttons={[]}
                        defConfig={defConfig}
                        defLang={defLang}
                      />
                    ) : (
                      <LoadingForm
                        iFrameHeight={wHeight - 158}
                        datatitle={
                          customPageConfig.datatitle
                            ? customPageConfig.datatitle
                            : ""
                        }
                        data={finalFormFields}
                        formValidation={formValidation}
                        defConfig={defConfig}
                        defLang={defLang}
                      />
                    )}
                  </>
                )}
              </div>
            ) : (
              <>
                {dashboards.length >= 1 && (
                  <>
                    <Report
                      filter={filter}
                      datapostload={datapostload}
                      dashboards={dashboards}
                      title={
                        customPageConfig.datatitle
                          ? customPageConfig.datatitle
                          : ""
                      }
                    />
                  </>
                )}
              </>
            )}
            {actions && (
              <div className="form-container text-right">
                {actions.map((key, index) => {
                  return (
                    <ButtonComponent
                      key={"cb" + index}
                      className={"ml-10 e-primary"}
                      name={key.OPTIONCODE}
                      onClick={submitForm}
                      style={{
                        fontWeight: "500",
                        position: "relative",
                        top: 8,
                        marginRight: 2,
                        textTransform: "capitalize",
                      }}
                    >
                      {key.OPTIONVALUE}
                    </ButtonComponent>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ManageCustomPage.propTypes = {
  //  window: ManageCustomPage.func,
};

export default ManageCustomPage;
