import * as React from "react";
import {
  TextBoxComponent,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import {
  ButtonComponent,
  CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";
import "./properties.component.css";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import {
  DataManager,
  Query,
  WebApiAdaptor,
  WebMethodAdaptor,
} from "@syncfusion/ej2-data";
import { getCookie } from "../../utils/Functions";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Toolbar,
  Page,
  Sort,
  RowDD,
  Selection,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import Loading from "../customdesign/Loading";

var gridOptInstance;
var apimethodObj;
var tabInstanceOpt;
class CompFormOptionGrid extends React.Component {
  constructor(props) {
    super(props);
    //this.onAdd = this.onAdd.bind(this);
    this.state = {
      // isExecuting:true ,
      // dataSource : [],
      option: "",
      value: "",
      width: "",
      headertext: [{ text: "Static Data" }, { text: "Dynamic Data" }],
      options: [],
      optionText: "",
      optionValue: "",
      optionWidth: "120",

      apiurl: "",
      apidata: "",
      text: "",
      name: this.props.name,
      type: this.props.type,

      apimethod: "get",
      apitoken: "NOTOKEN",
      datatype: "static",
      masterLoading: true,
      // gridCommands : [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }],
      // editSettings : { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
    };
  }

  componentDidMount = () => {
    this.setState({ ...this.state });

    let isStatic = false;
    let isDynamic = false;
    //console.log("props", this.props);
    if (this.props.value) {
      if (this.props.value.datatype == "dynamic") {
        isDynamic = true;
      } else {
        isStatic = true;
      }
    }

    //console.log(isStatic, isDynamic);
    if (isStatic) {
      setTimeout(
        async function () {
          this.setState({
            ...this.state,
            options: this.props.value.options ? this.props.value.options : [],
            masterLoading: false,
          });
        }.bind(this),
        250
      );
    } else {
      if (isDynamic) {
        setTimeout(
          async function () {
            this.setState({
              ...this.state,
              apidata: this.props.value.apidata,
              apimethod: this.props.value.apimethod,
              apitoken: this.props.value.apitoken,
              apiurl: this.props.value.apiurl,
              datatype: this.props.value.datatype,
              text: this.props.value.text,
              value: this.props.value.value,
              width: this.props.width.value,

              datatype: "dynamic",
              masterLoading: false,
            });
          }.bind(this),
          250
        );
        setTimeout(
          async function () {
            tabInstanceOpt.select(1);
          }.bind(this),
          300
        );
      } else {
        this.setState({ ...this.state, masterLoading: false });
      }
    }
  };

  addOption = (event) => {
    if (this.state.optionText !== "" && this.state.optionValue !== "") {
      const options = this.state.options;

      options.push({
        value: this.state.optionValue,
        text: this.state.optionText,
        width: this.state.optionWidth || "120",
      });
      this.setState({ ...this.state, loading: true });

      setTimeout(
        async function () {
          this.setState({
            ...this.state,
            optionText: "",
            optionValue: "",
            optionWidth: "120",

            loading: false,
          });
          console.log("target", this.state)
          this.props.onChange({ target: this.state });
        }.bind(this),
        250
      );
    }
  };

  deleteRow = (index) => {
    const options = this.state.options;
    options.splice(index, 1);
    this.setState({ ...this.state, loading: true });
    setTimeout(
      async function () {
        this.setState({
          ...this.state,
          optionText: "",
          optionValue: "",
          optionWidth: "120",

          loading: false,
        });
        this.props.onChange({ target: this.state });
      }.bind(this),
      150
    );
  };
  onChange = async (event) => {
    try {
      if (event.target) {
        this.setState({
          ...this.state,
          [event.target.name]: event.target.value,
        });
      } else {
        this.setState({ ...this.state, apimethod: apimethodObj.value });
      }
      if (!event.target?.temporal) {
        console.log("Aqui!")
        this.props.onChange({ target: this.state });
      }
    } catch (Exc) {
      console.log(">>>>Err", Exc);
      if (action != "") {
        console.log("action", action);
      }
    }
  };

  changePanel = (args) => {
    if (args.selectedIndex === 0) {
      this.setState({ ...this.state, datatype: "static" });
    } else {
      this.setState({ ...this.state, datatype: "dynamic" });
    }

    setTimeout(
      async function () {
        this.props.onChange({ target: this.state });
      }.bind(this),
      10
    );
  };
  testApi = () => {
    var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");

    if (this.state.apitoken) {
      if (this.state.apitoken !== "" && this.state.apitoken !== "NOTOKEN") {
        token = this.state.apitoken;
      }
    }
    let url = this.state.apiurl;
    if (this.state.apiurl.indexOf("http") < 0) {
      url = process.env.REACT_APP_API_CONFIG + this.state.apiurl;
    }
    var remoteData = new DataManager({
      url: url,
      adaptor:
        this.state.apimethod === "post"
          ? new WebMethodAdaptor()
          : new WebApiAdaptor(),
      headers: [
        { Authorization: "Bearer " + token },
        { getInfo: "simplified" },
      ],
      crossDomain: true,
    });

    let remoteFields = { text: this.state.text, value: this.state.value };
    let query = new Query()
      .addParams("$apidata", this.state.apidata)
      .select([this.state.text, this.state.value])
      .take(1000)
      .requiresCount();

    this.setState({ ...this.state, loading: true });

    setTimeout(
      async function () {
        this.setState({
          ...this.state,
          remoteData: remoteData,
          query: query,
          remoteFields: remoteFields,
          loading: false,
        });
      }.bind(this),
      500
    );
  };

  tabContent1 = () => {
    return (
      <div style={{ height: 330 }}>
        <div className="row mt-3 mb-1">
          <div className="col-sm-4">
            <TextBoxComponent
              name={"optionValue"}
              placeholder={"Value"}
              key={"optionValue"}
              onChange={({ value }) =>
                this.onChange({
                  target: { name: "optionValue", value, temporal: true },
                })
              }
              floatLabelType="Auto"
            />
          </div>
          <div className="col-sm-3">
            <TextBoxComponent
              key={"optionText"}
              name={"optionText"}
              placeholder={"Text"}
              onChange={({ value }) =>
                this.onChange({
                  target: { name: "optionText", value, temporal: true },
                })
              }
              floatLabelType="Auto"
            />
          </div>

          <div className="col-sm-3">
            <TextBoxComponent
              key={"optionWidth"}
              name={"optionWidth"}
              placeholder={"Col. Width"}
              onChange={({ value }) =>
                this.onChange({
                  target: { name: "optionWidth", value, temporal: true },
                })
              }
              floatLabelType="Auto"
            />
          </div>

          <div className="col-sm-2">
            <ButtonComponent
              key={"optionButton"}
              className={"ml-10 e-success btn-circle"}
              id={"optionButton"}
              name={"optionButton"}
              onClick={(this.addOption = this.addOption.bind(this))}
              style={{ textTransform: "uppercase", fontWeight: "500" }}
            >
              +
            </ButtonComponent>
          </div>
        </div>

        <div className=" row pl-1">
          <GridComponent
            id="gridOption"
            ref={(grid) => (gridOptInstance = grid)}
            height="180"
            width={"98%"}
            allowRowDragAndDrop={true}
            dataSource={this.state.options}
            allowPaging={false}
            allowTextWrap={true}
          >
            <ColumnsDirective>
              <ColumnDirective
                width={100}
                field={"value"}
                headerText={"Value"}
              ></ColumnDirective>
              <ColumnDirective
                width={100}
                field={"text"}
                headerText={"Text"}
              ></ColumnDirective>
              <ColumnDirective
                width={100}
                field={"width"}
                headerText={"Col. Width"}
              ></ColumnDirective>
              <ColumnDirective
                field={"manager"}
                headerText=""
                width="30"
                template={this.delTemplate.bind(this)}
              ></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Edit, Sort, RowDD, Selection]} />
          </GridComponent>
        </div>
      </div>
    );
  };
  loadingData = () => {
    return (
      <div style={{ height: 330 }}>
        <Loading />
      </div>
    );
  };
  delTemplate = (props) => {
    return (
      <div className="text-center">
        <span
          class="e-btn-icon e-icons e-delete"
          style={{ cursor: "pointer" }}
          onClick={() => this.deleteRow(props.index)}
        ></span>
      </div>
    );
  };

  tabContent2 = () => {
    return (
      <div style={{ height: 330 }}>
        <div className="row mt-3">
          <div className="col-sm-12 col-lg-6 mt-2">
            <DropDownListComponent
              dataSource={[
                { text: "get", value: "get" },
                { text: "post", value: "post" },
              ]}
              fields={{ text: "text", value: "value" }}
              name={"apimethod"}
              placeholder={"Api Method"}
              floatLabelType="Auto"
              value={this.state.apimethod}
              ref={(dropdownlist) => {
                apimethodObj = dropdownlist;
              }}
              change={this.onChange}
            />
          </div>
          <div className="col-sm-12 col-lg-6  mt-2">
            <TextBoxComponent
              name={"apiurl"}
              value={this.state.apiurl}
              placeholder={"Api url"}
              onChange={({ value }) =>
                this.onChange({ target: { name: "apiurl", value } })
              }
              floatLabelType="Auto"
            />
          </div>

          <div className="col-sm-12 col-lg-6  mt-2">
            <TextBoxComponent
              name={"apitoken"}
              value={this.state.apitoken}
              placeholder={"Api Token"}
              onChange={({ value }) =>
                this.onChange({ target: { name: "apitoken", value } })
              }
              floatLabelType="Auto"
            />
          </div>

          <div className="col-sm-12 col-lg-6 mt-2">
            <TextBoxComponent
              name={"apidata"}
              value={this.state.apidata}
              placeholder={"Api data (to send API)"}
              onChange={({ value }) =>
                this.onChange({ target: { name: "apidata", value } })
              }
              floatLabelType="Auto"
            />
          </div>

          <div className="col-sm-12 col-lg-6 mt-2">
            <TextBoxComponent
              name={"value"}
              value={this.state.value}
              placeholder={"Field Value"}
              onChange={({ value }) =>
                this.onChange({ target: { name: "value", value } })
              }
              floatLabelType="Auto"
            />
          </div>
          <div className="col-sm-12 col-lg-6 mt-2">
            <TextBoxComponent
              name={"text"}
              value={this.state.text}
              placeholder={"Field Text"}
              onChange={({ value }) =>
                this.onChange({ target: { name: "text", value } })
              }
              floatLabelType="Auto"
            />
          </div>
        </div>

        <div className="row fixed-options-row">
          <div className="col-sm-4 mb-1 col-lg-3 text-left">
            <ButtonComponent
              key={"optionButton"}
              className={"e-success e-btn btn-block"}
              id={"optionButton"}
              name={"optionButton"}
              onClick={(this.testApi = this.testApi.bind(this))}
              style={{ textTransform: "uppercase", fontWeight: "500" }}
            >
              Test
            </ButtonComponent>
          </div>
          <div className="col-sm-8  col-lg-9 text-left ">
            {this.state.remoteData ? (
              <DropDownListComponent
                dataSource={this.state.remoteData}
                query={this.state.query}
                fields={this.state.remoteFields}
                placeholder={"Preview"}
                floatLabelType="Auto"
              />
            ) : (
              <DropDownListComponent
                dataSource={[
                  { text: "[Please set param then click en TEST]", value: "0" },
                ]}
                fields={{ text: "text", value: "value" }}
                placeholder={"Preview"}
                floatLabelType="Auto"
                value="0"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.state.masterLoading ? (
      <Loading />
    ) : (
      <div className="my-1 e-card e-custom-card">
        <div className="row no-padding">
          <div className="col-sm-12  no-padding ">
            <span className="comp-label"> {this.props.label}</span>
          </div>
        </div>

        <div className="control-section tab-control-section">
          {/* Render the Tab Component */}
          <TabComponent
            selected={this.changePanel}
            ref={(tab) => (tabInstanceOpt = tab)}
            cssClass="responsive-mode"
            heightAdjustMode="None"
            height="auto"
            width="auto"
            id="defaultTab"
            className="full-tabs"
          >
            <TabItemsDirective>
              <TabItemDirective
                header={this.state.headertext[0]}
                content={
                  this.state.loading ? this.loadingData : this.tabContent1
                }
              />
              <TabItemDirective
                header={this.state.headertext[1]}
                content={
                  this.state.loading ? this.loadingData : this.tabContent2
                }
              />
            </TabItemsDirective>
          </TabComponent>
        </div>
      </div>
    );
  }
}

export default CompFormOptionGrid;
