import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../redux/actions/process.actions";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";

import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import { simplePostData, setCookie, getCookie } from "../../utils/Functions";
import ApiClient from "../../api/ApiClient";
var listList;
var listDialogInstance;

const DialogSetHeaderColumns = (props) => {
  const { defConfig, defLang } = useSelector((state) => state.Login);
  const {
    componentConfig,
    processSuccess,
    processError,
    requesting,
    processMessage,
    initialValues,
    validationSchema,
    rows,
  } = useSelector((state) => state.Process);

  const header = defConfig[defLang]["setHeaderColumns"];

  const [list, setList] = React.useState([]);

  const [fields, setFields] = React.useState({});

  React.useEffect(() => {
    //console.log("DDD", props)

    if (props.columns) {
      if (props.columns.length > 0) {
        //console.log("cols");
        refreshColumn(props.id);
      }
    }
    //setList(rows)
  }, [props]);

  const refreshColumn = (id) => {
    let _filterdCols = getCookie(
      process.env.REACT_APP_NAME + "_reportPage_" + id
    );
    let filteredColes = [];
    if (_filterdCols) {
      filteredColes = JSON.parse(_filterdCols);
    } else {
      setCookie(
        process.env.REACT_APP_NAME + "_reportPage_" + id,
        "[]",
        process.env.REACT_APP_SESSION_DURATION
      );
    }
    //console.log("final", filteredColes);
    let fixedColumn = [];
    for (let i in props.columns) {
      let key = props.columns[i];
      let pos = filteredColes.findIndex((item) => item === key.value);
      //console.log(key.value, pos);
      let isChecked = true;
      if (pos >= 0) {
        isChecked = false;
      }

      key["isChecked"] = isChecked;
      fixedColumn.push(key);
    }
    setList([]);

    setTimeout(
      function () {
        setList(fixedColumn);
      }.bind(this),
      20
    );

    //console.log("fixedColumn", fixedColumn);
  };
  const submitForm = async (event) => {
    //console.log("values", listList.getSelectedItems().data);

    let selectedItems =  listList
    .getSelectedItems()
    .data;
    let apiName = "set-actors-users";
    let excluded = [];
    for (let i in props.columns) {
      let key = props.columns[i];
      let pos = selectedItems.findIndex((item) => item.value === key.value);
      if (pos < 0) {
        excluded.push(key.value);
      }
    }
    
    setCookie(
      process.env.REACT_APP_NAME + "_reportPage_" + props.id,
      JSON.stringify(excluded),
      process.env.REACT_APP_SESSION_DURATION
    );

    setTimeout(
      function () {
        refreshColumn();
      }.bind(this),
      10
    );

    setTimeout(
      function () {
        props.updateColumns(excluded);
      }.bind(this),
      30
    );

    listDialogInstance.hide();
  };

  return (
    <DialogComponent
      id="listDialogInstance"
      showCloseIcon={true}
      closeOnEscape={false}
      animationSettings={props.animationSettings}
      ref={(dialog) => (listDialogInstance = dialog)}
      isModal={true}
      open={props.open}
      close={props.close}
      header={header}
      buttons={[
        {
          click: () => {
            submitForm();
          },
          buttonModel: {
            content: defConfig[defLang].savelabel,
            isPrimary: true,
          },
        },
        {
          click: () => {
            //props.close();
            listDialogInstance.hide();
          },
          buttonModel: {
            content: defConfig[defLang].cancellabel,
            isSecondary: true,
          },
        },
      ]}
      height="100%"
      className="partial-form"
      position={{ X: "right", Y: "top" }}
      visible={props.status}
    >
      <div className="control-pane">
        <div className="control-section">
          <h4> {defConfig[defLang]["setHeaderColumnsText"]}</h4>
          <div className="col-12 mt-10">
            {!requesting && (
              <ListViewComponent
                id="view-list-flat"
                ref={(dialog) => (listList = dialog)}
                fields={fields}
                dataSource={list}
                showCheckBox={true}
              ></ListViewComponent>
            )}
          </div>
        </div>
      </div>
    </DialogComponent>
  );
};
DialogSetHeaderColumns.propTypes = {};

export default DialogSetHeaderColumns;
