import * as React from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ButtonComponent,
  CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "./properties.component.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import GenericForm from "./GenericForm";
import Utils from "../../utils/Utils";
import {
  prepareForm,
  parseFormData,
  clearformData,
  find,
  mergeFormData,
} from "../../utils/Functions";
let animationSettings = { effect: "Fade" };
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-react-layouts";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import { MapAjax } from "@syncfusion/ej2-maps";
import {
  MapsComponent,
  LayersDirective,
  LayerDirective,
  MarkersDirective,
  MarkerDirective,
  Marker,
  Legend as MapsLegend,
  MapsTooltip,
} from "@syncfusion/ej2-react-maps";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  Legend,
  PieSeries,
  AccumulationTooltip,
  SeriesCollectionDirective,
  AccumulationDataLabel,
  ChartComponent,
  ColumnSeries,
  Category,
  SeriesDirective,
  Tooltip,
  DataLabel,
  DateTime,
  SplineAreaSeries,
  Inject,
} from "@syncfusion/ej2-react-charts";
import { columnSelectionComplete } from "@syncfusion/ej2-react-grids";

export let data1 = [
  { x: "GBR", y: 27, toolTipMappingName: "Great Britain" },
  { x: "CHN", y: 26, toolTipMappingName: "China" },
  { x: "AUS", y: 8, toolTipMappingName: "Australia" },
  { x: "RUS", y: 19, toolTipMappingName: "Russia" },
  { x: "GER", y: 17, toolTipMappingName: "Germany" },
  { x: "UA", y: 2, toolTipMappingName: "Ukraine" },
  { x: "ES", y: 7, toolTipMappingName: "Spain" },
  { x: "UZB", y: 4, toolTipMappingName: "Uzbekistan" },
  { x: "JPN", y: 12, toolTipMappingName: "Japan" },
  { x: "NL", y: 8, toolTipMappingName: "NetherLand" },
  { x: "USA", y: 46, toolTipMappingName: "United States" },
];
export let data2 = [
  { x: "GBR", y: 23, toolTipMappingName: "Great Britain" },
  { x: "CHN", y: 18, toolTipMappingName: "China" },
  { x: "AUS", y: 11, toolTipMappingName: "Australia" },
  { x: "RUS", y: 17, toolTipMappingName: "Russia" },
  { x: "GER", y: 10, toolTipMappingName: "Germany" },
  { x: "UA", y: 5, toolTipMappingName: "Ukraine" },
  { x: "ES", y: 4, toolTipMappingName: "Spain" },
  { x: "UZB", y: 2, toolTipMappingName: "Uzbekistan" },
  { x: "JPN", y: 8, toolTipMappingName: "Japan" },
  { x: "NL", y: 7, toolTipMappingName: "NetherLand" },
  { x: "USA", y: 37, toolTipMappingName: "United States" },
];
export let data3 = [
  { x: "GBR", y: 17, toolTipMappingName: "Great Britain" },
  { x: "CHN", y: 26, toolTipMappingName: "China" },
  { x: "AUS", y: 10, toolTipMappingName: "Australia" },
  { x: "RUS", y: 20, toolTipMappingName: "Russia" },
  { x: "GER", y: 15, toolTipMappingName: "Germany" },
  { x: "UA", y: 24, toolTipMappingName: "Ukraine" },
  { x: "ES", y: 6, toolTipMappingName: "Spain" },
  { x: "UZB", y: 7, toolTipMappingName: "Uzbekistan" },
  { x: "JPN", y: 8, toolTipMappingName: "Japan" },
  { x: "NL", y: 4, toolTipMappingName: "NetherLand" },
  { x: "USA", y: 38, toolTipMappingName: "United States" },
];

var expensedata = [
  {
    UniqueId: "T100003",
    DateTime: new Date(1488359820000),
    Category: "Food",
    PaymentMode: "Cash",
    TransactionType: "Expense",
    Description: "Confederate cush",
    Amount: "900",
    MonthShort: "Mar",
    MonthFull: "March, 2017",
    FormattedDate: "03/01/2017 08:53 PM",
    Device: "Tablet",
  },
  {
    UniqueId: "T100004",
    DateTime: new Date(1491038220000),
    Category: "Transportation",
    PaymentMode: "Credit Card",
    TransactionType: "Expense",
    Description: "Public and other transportation",
    Amount: "1200",
    MonthShort: "Apr",
    MonthFull: "April, 2017",
    FormattedDate: "04/01/2017 10:44 AM",
    Device: "Desktop",
  },
  {
    UniqueId: "T100005",
    DateTime: new Date(1493630220000),
    Category: "Transportation",
    PaymentMode: "Cash",
    TransactionType: "Expense",
    Description: "Public and other transportation",
    Amount: "600",
    MonthShort: "May",
    MonthFull: "May, 2017",
    FormattedDate: "05/01/2017 03:25 PM",
    Device: "Mobile",
  },
];

class CompDashboardDesinger extends React.Component {
  constructor(props) {
    super(props);
    //this.onAdd = this.onAdd.bind(this);
    this.state = {
      isExecuting: true,
      dataSource: [],
      dashboardObj: {},
      dialogInstanceField: {},
      dialogInstanceField1: {},
      count: 0,
      formFields: [],
      datapostload:
        ' console.log("init load options", formData); if (formData.type.value==="bar" || formData.type.value==="pie" ){ formData.values.type="optionsChart" }else{ formData.values.type="optionsGrid" }  ',
      formVisible: false,
      completeFields: [],
      sizeCol: 6,
      fields: [],
      fieldId: "",
      filedOptions: [
        { text: "Full Size", value: 12 },
        { text: "Medium Size", value: 6 },
        { text: "Small", value: 4 },
      ],
    };
  }

  componentDidMount = async () => { 
    let formFields = await prepareForm(Utils.dashboardPanelEditor);
    document.removeEventListener("click", this.checkClick, true);
    document.addEventListener("click", this.checkClick);

    let fields = [];
    let count = 0;
    setTimeout(
      async function () {
        for (let i in this.props.value) {
          let col = this.props.value[i];
          let _id = parseInt(col.id);
          if (count < _id) {
            count = _id;
          }
          let panel = [
            {
              id: col.layout_id,
              sizeX: col.layout_sizeX,
              sizeY: col.layout_sizeY,
              row: col.layout_row,
              col: col.layout_col,
              minSizeY: col.layout_maxSizeY,
              maxSizeY: col.layout_minSizeY,
              header:
                '<span id="edit" class="e-btn-icon e-edit e-icons e-edit-icon "></span>  <span id="close" class="e-close-icon e-clear-icon"></span> ' +
                " <div>Panel: " +
                col.title +
                " </div>",
              content: col.type === "grid" ? this.grid : this.bar,
            },
          ];
          this.state.dashboardObj.addPanel(panel[0]);
          col["id"] = parseInt(col["id"]);
          fields.push(col);
        }
        this.setState({ formFields: formFields, fields: fields, count: count });
      }.bind(this),
      10
    );
  };

  checkClick = (event) => {
    try {
      if (event.target.parentNode) {
        if (event.target.parentNode.id) {
          if (event.target.parentNode.id.indexOf("layouttemplate") >= 0) {
            if (event.target.id === "edit") {
              this.onEditIconHandler(event);
            } else if (event.target.id === "close") {
              this.onCloseIconHandler(event);
            }
          }
        }
      }
    } catch (Exc) {
      console.log(">>>>>", Exc);
    }
  };
  componentWillUnmount() {
    document.removeEventListener("click", this.checkClick, true);
  }
  onChange = (event) => {
    console.log("entra a comp", event.target.name);
    const formFields = this.state.formFields.map(function (a) {
      let val = "";
      if (a["name"] == event.target.name) {
        if (event.target.type) {
          if (event.target.type === "switch") {
            if (a["value"] === "") {
              val = "YES";
            } else {
              val = "";
            }
            a["value"] = val;
          } else if (event.target.type === "options") {
            let data = event.target;
            let value = {};
            if (data.datatype == "static") {
              value = {
                datatype: data.datatype,
                options: data.options,
              };
              //   a["values"] = data.options
              a["value"] = value;
            } else {
              value = {
                datatype: data.datatype,
                apidata: data.apidata,
                apimethod: data.apimethod,
                apitoken: data.apitoken,
                apiurl: data.apiurl,
                text: data.text,
                value: data.value,
              };
              val = value;
              // a["api"] = value
              a["value"] = value;
            }
          } else if (event.target.type === "optionsGrid") {
            let data = event.target;
            let value = {};
            if (data.datatype == "static") {
              value = {
                datatype: data.datatype,
                options: data.options,
              };
              //   a["values"] = data.options
              a["value"] = value;
            } else {
              value = {
                datatype: data.datatype,
                apidata: data.apidata,
                apimethod: data.apimethod,
                apitoken: data.apitoken,
                apiurl: data.apiurl,
                text: data.text,
                value: data.value,
              };
              val = value;
              // a["api"] = value
              a["value"] = value;
            }
          } else if (event.target.type === "optionsChart") {
            let data = event.target;
            let value = {};
            if (data.datatype == "static") {
              value = {
                datatype: data.datatype,
                options: data.options,
              };
              //   a["values"] = data.options
              a["value"] = value;
            } else {
              value = {
                datatype: data.datatype,
                apidata: data.apidata,
                apimethod: data.apimethod,
                apitoken: data.apitoken,
                apiurl: data.apiurl,
                text: data.text,
                value: data.value,
              };
              val = value;
              // a["api"] = value
              a["value"] = value;
            }
          }
        } else {
          val = event.target.value;
          a["value"] = val;
        }
      }
      return a;
    });

    //console.log("formFields",formFields)
  };

  onBeginAdd = (event) => {
    let formFields = clearformData(this.state.formFields);
    this.setState({ formFields: [], sizeCol: parseInt(event.target.id) });
    setTimeout(
      async function () {
        this.setState({
          formFields: formFields,
          formVisible: true,
          action: "create",
        });
        //this.state.dialogInstanceField.show()
      }.bind(this),
      10
    );
  };

  load = (args) => {
    let selectedTheme = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.accumulation.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/contrast/i, "Contrast")
      .replace(/-dark/i, "Dark");
  };
  Chartload = (args) => {
    let selectedTheme = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/contrast/i, "Contrast")
      .replace(/-dark/i, "Dark");
    if (selectedTheme === "highcontrast") {
      args.chart.series[0].marker.dataLabel.fill = "#000000";
      args.chart.series[1].marker.dataLabel.fill = "#000000";
    }
  };

  bar = (args) => {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <ChartComponent
          id="colChart"
          style={{ textAlign: "center" }}
          load={this.Chartload.bind(this)}
          legendSettings={{ visible: false }}
          primaryXAxis={{
            valueType: "Category",
            interval: 1,
            majorGridLines: { width: 0 },
          }}
          primaryYAxis={{
            majorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            labelStyle: { color: "transparent" },
          }}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true }}
        >
          <Inject
            services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={[
                { x: "Jan", y: 46 },
                { x: "Feb", y: 27 },
                { x: "Mar", y: 26 },
              ]}
              xName="x"
              yName="y"
              name="Desktop"
              type="Column"
              marker={{
                dataLabel: {
                  visible: false,
                  position: "Top",
                  font: { fontWeight: "600", color: "#ffffff" },
                },
              }}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={[
                { x: "Jan", y: 37 },
                { x: "Feb", y: 23 },
                { x: "Mar", y: 18 },
              ]}
              xName="x"
              yName="y"
              name="Mobile"
              type="Column"
              marker={{
                dataLabel: {
                  visible: false,
                  position: "Top",
                  font: { fontWeight: "600", color: "#ffffff" },
                },
              }}
              fill="#e56691"
            ></SeriesDirective>
            <SeriesDirective
              dataSource={[
                { x: "Jan", y: 38 },
                { x: "Feb", y: 17 },
                { x: "Mar", y: 26 },
              ]}
              xName="x"
              yName="y"
              name="Tablet"
              type="Column"
              marker={{
                dataLabel: {
                  visible: false,
                  position: "Top",
                  font: { fontWeight: "600", color: "#ffffff" },
                },
              }}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    );
  };

  pie = (args) => {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AccumulationChartComponent
          id="pie"
          legendSettings={{ visible: false }}
          enableSmartLabels={true}
          height="100%"
          width="100%"
          enableAnimation={false}
          selectionMode={"Point"}
          center={{ x: "50%", y: "50%" }}
          tooltip={{
            enable: false,
            header: "<b>${point.x}</b>",
            format: "Composition : <b>${point.y}%</b>",
          }}
          load={this.load.bind(this)}
        >
          <Inject
            services={[
              AccumulationLegend,
              PieSeries,
              AccumulationTooltip,
              AccumulationDataLabel,
            ]}
          />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              dataSource={expensedata}
              name="Revenue"
              xName="Device"
              yName="Amount"
              explode={false}
              dataLabel={{
                visible: true,
                position: "Inside",
                name: "text",
                font: {
                  fontWeight: "600",
                },
              }}
              radius="100%"
              innerRadius="35%"
              palettes={["#357cd2", "#00bdae", "#e36593"]}
            ></AccumulationSeriesDirective>
          </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
      </div>
    );
  };

  grid = (args) => {
    //console.log(args);
    return (
      <div style={{ height: "100%", width: "100%", padding: 10 }}>
        <GridComponent
          dataSource={[
            {
              Field1: 49,
              Field2: "2023-07-05",
              Field3: "Demo data 1",
              Field4: 10.5,
            },
            {
              Field1: 50,
              Field2: "2023-07-06",
              Field3: "Demo data 2",
              Field4: 7.5,
            },
            {
              Field1: 51,
              Field2: "2023-07-07",
              Field3: "Demo data 3",
              Field4: 44,
            },
          ]}
          className="demo-grid"
        >
          <ColumnsDirective>
            <ColumnDirective field="Field1" width="50" headerText="Field 1" />
            <ColumnDirective field="Field3" width="100" headerText="Field 3" />
            <ColumnDirective
              field="Field2"
              width="70"
              headerText="Field 2"
              format="yMd"
            />
            <ColumnDirective
              field="Field4"
              width="70"
              format="C2"
              headerText="Field 4"
              textAlign="Right"
            />
          </ColumnsDirective>
        </GridComponent>
      </div>
    );
  };

  prepareField = (event) => {
    let formFields = clearformData(this.state.formFields);
    this.setState({ formFields: [] });
    setTimeout(
      async function () {
        this.setState({
          formFields: formFields,
          formVisible: true,
          action: "create",
        });
        this.state.dialogInstanceField1.hide();
      }.bind(this),
      10
    );
  };

  onChangeS = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveField = (event) => {
    let formData = parseFormData(this.state.formFields);

    //console.log(this.state.formFields, formData);

    const proxy = this.state;
    let row = 0;
    let col = 0;
    let dataSource = proxy.dashboardObj.serialize();
    let maxRow = 0;
    const fields = this.state.fields;

    if (this.state.action == "update") {
      let pos = find(dataSource, this.state.fieldId, "id");
      let pos2 = find(this.state.fields, parseInt(this.state.fieldId), "id");
      formData["id"] = fields[pos2].id;
      fields[pos2] = formData;
      let id = proxy.fieldId.replace("_layout", "");
      this.setState({ fields: fields });
      (proxy.dashboardObj.panels[pos]["header"] =
        '<span id="edit" class="e-btn-icon e-edit e-icons e-edit-icon "></span>  <span id="close" class="e-close-icon e-clear-icon"></span> ' +
        " <div>Panel Type: " +
        formData.type +
        " </div>"),
        (proxy.dashboardObj.panels[pos]["content"] =
          formData.type === "grid" ? this.grid : this.bar);
      /*'<div class="row no-padding"> <div  class="col-xs-6"> <p>Label: '+formData.label+'</p>  <p>Type : '+formData.type+'</p> </div>'+
                    (proxy.dashboardObj.panels[pos].sizeX >=4 ?  '  <div  class="col-md-6"></div>':'')+
            '</div>'*/
    } else {
      this.state.count = this.state.count + 1;
      for (let r in dataSource) {
        if (dataSource[r].row > maxRow) {
          maxRow = dataSource[r].row;
        }
      }
      let maxCol = 0;
      let totalCol = 0;
      for (let c in dataSource) {
        if (dataSource[c].row == maxRow) {
          totalCol = totalCol + dataSource[c].sizeX;
          if (dataSource[c].col > maxCol) {
            maxCol = dataSource[c].col;
          }
        }
      }
      if (dataSource.length > 0) {
        if (totalCol + this.state.sizeCol > 12) {
          row = maxRow + 4;
        } else {
          col = totalCol;
          row = maxRow;
        }
      }
      let panel = [
        {
          id: this.state.count.toString() + "_layout",
          sizeX: this.state.sizeCol,
          sizeY: 2,
          row: row,
          col: col,
          minSizeY: 4,
          maxSizeY: 12,
          header:
            '<span id="edit" class="e-btn-icon e-edit e-icons e-edit-icon "></span>  <span id="close" class="e-close-icon e-clear-icon"></span> ' +
            " <div>Panel: " +
            formData.title +
            " </div>",
          content: formData.type === "bar" ? this.bar : this.grid,
        },
      ];
      formData["id"] = this.state.count.toString();

      fields.push(formData);
      proxy.dashboardObj.addPanel(panel[0]);
      /*
            let closeIcon = document.getElementById(this.state.count.toString() + '_layout').querySelector('.e-clear-icon');
            closeIcon.addEventListener('click', this.onCloseIconHandler.bind(this));
            let editIcon = document.getElementById(this.state.count.toString() + '_layout').querySelector('.e-edit-icon');
            editIcon.addEventListener('click', this.onEditIconHandler.bind(this));
            */
    }
    this.onChangeForm();
    this.state.dialogInstanceField.hide();
  };

  onChangeForm() {
    let dataSource = this.state.dashboardObj.serialize();
    const fields = this.state.fields;
    var values = [];
    fields.map(function (f) {
      let newObj = f;
      dataSource.map(function (d) {
        if (f.id + "_layout" === d.id) {
          newObj["layout_id"] = d.id;
          // newObj["layout_maxSizeX"] =  d.maxSizeX
          newObj["layout_maxSizeY"] = d.maxSizeY;
          //newObj["layout_minSizeX"] =  d.minSizeX
          newObj["layout_minSizeY"] = d.minSizeY;
          newObj["layout_row"] = d.row;
          newObj["layout_col"] = d.col;
          newObj["layout_sizeX"] = d.sizeX;
          newObj["layout_sizeY"] = d.sizeY;
        }
      });
      values.push(newObj);
    });

    this.props.onChange({ target: { name: this.props.name, value: values } });
  }

  onEditIconHandler(event) {
    let proxy = this.state;
    let panel = event.target;
    const fields = this.state.fields;
    let pos = find(fields, parseInt(panel.offsetParent.id), "id");
    let formFields = clearformData(this.state.formFields);
    //console.log(pos, this.state.fields[pos], formFields);
    this.setState({ formFields: [] });
    setTimeout(
      async function () {
        formFields = mergeFormData(formFields, this.state.fields[pos]);
        this.setState({
          formFields: formFields,
          formVisible: true,
          action: "update",
          fieldId: panel.offsetParent.id,
        });
      }.bind(this),
      10
    );
  }
  onCloseIconHandler(event) {
    let proxy = this.state;
    let panel = event.target;
    const fields = this.state.fields;
    let pos = find(fields, parseInt(panel.offsetParent.id), "id");
    fields.splice(pos, 1);

    if (panel.offsetParent) {
      proxy.dashboardObj.removePanel(panel.offsetParent.id);
      this.onChangeForm();
    }
  }

  //not used
  /*
    remove() {
        if (this.state.dashboardObj.panels.length > 0) {
            for (let i = this.state.dashboardObj.panels.length - 1; i < this.state.dashboardObj.panels.length; i++) {
                this.state.dashboardObj.removePanel(this.state.dashboardObj.panels[this.state.dashboardObj.panels.length - 1 - i].id);
            }
        }
    }*/
  //not used
  onCellChange(args) {
    this.state.dashboardObj.cellSpacing = [
      parseInt(args.value, 10),
      parseInt(args.value, 10),
    ];
  }
  //not used
  /*
    onChange(args) {
        let proxy = this.state;
        if (args.event.target.previousElementSibling.id === 'floating') {
            proxy.dashboardObj.allowFloating = args.checked;
        }
        if (args.event.target.previousElementSibling.id === 'resizing') {
            proxy.dashboardObj.allowResizing = args.checked;
        }
    }*/

  content = () => {
    return (
      <div className="mb-2">
        <GenericForm
          data={this.state.formFields.length > 0 ? this.state.formFields : []}
          onChange={this.onChange}
          buttons={[]}
        />
      </div>
    );
  };
  render() {
    return (
      <div className="row">
        <DialogComponent
          id="dialogInstanceField1"
          closeOnEscape={true}
          isModal={true}
          showCloseIcon={true}
          ref={(dialog) => (this.state.dialogInstanceField1 = dialog)}
          buttons={[
            {
              click: () => {
                this.prepareField();
              },
              buttonModel: {
                content: "Create",
                isPrimary: true,
                type: "button",
              },
            },
            {
              click: () => {
                this.state.dialogInstanceField1.hide();
              },
              buttonModel: {
                content: "Cancel",
                isPrimary: false,
                type: "button",
              },
            },
          ]}
          header={"Add New Panel"}
          width="235px"
          visible={false}
          animationSettings={animationSettings}
        >
          <div style={{ minHeight: 80, paddingTop: 25 }}>
            <div>
              <DropDownListComponent
                dataSource={this.state.filedOptions}
                fields={{ text: "text", value: "value" }}
                name="sizeCol"
                value={this.state.sizeCol}
                cssClass="e-outline"
                placeholder={"Field Size"}
                floatLabelType="Auto"
                select={({ itemData }) => {
                  let value = itemData.value;

                  this.onChangeS({ target: { name: "sizeCol", value: value } });
                }}
              />
            </div>
          </div>
        </DialogComponent>

        <div className="col-lg-12 control-section no-padding" id="control_dash">
          <div
            className="no-padding col-lg-12 property-section dashboard"
            id="api_property"
          >
            <div className="row field-list-header">
              <span>Dashboard List</span>
            </div>
          </div>

          <div style={{ padding: 10 }}>
            <div
              className="content-wrapper mt-50-web mt-10-mobile"
              style={{ maxWidth: "100%", minHeight: "127px" }}
            >
              <DashboardLayoutComponent
                id="default_dashboard"
                cssClass="custom-dashboard"
                columns={12}
                cellSpacing={[5, 5]}
                header={300}
                change={this.onCellChange.bind(this)}
                resizeStop={this.onCellChange.bind(this)}
                ref={(scope) => {
                  this.state.dashboardObj = scope;
                }}
                allowResizing={true}
              >
                <PanelsDirective></PanelsDirective>
              </DashboardLayoutComponent>
            </div>
          </div>
          <div style={{ padding: "0 10px" }}>
            <div
              className="col-lg-12 no-padding property-section dashboard"
              id="api_property"
            >
              <div className="mb-10 row">
                <div className="col-md-12 flex-right no-padding text-right">
                  <ButtonComponent
                    iconCss="e-icons e-plus"
                    type="button"
                    className="e-btn-square e-secondary"
                    onClick={() => {
                      this.state.dialogInstanceField1.show();
                    }}
                  >
                    New Panel
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.formFields.length > 0 && this.state.formVisible && (
          <DialogComponent
            id="editFieldDialog"
            animationSettings={animationSettings}
            width={"50%"}
            height={660}
            header="Panel Info"
            ref={(dialog) => (this.state.dialogInstanceField = dialog)}
            visible={this.state.formVisible}
            isModal={true}
            showCloseIcon={true}
            /*content={this.content.bind(this)}*/
            buttons={[
              {
                click: () => {
                  this.saveField();
                },
                buttonModel: {
                  content: "SAVE",
                  isPrimary: true,
                },
              },
              {
                click: () => {
                  //props.close();
                  this.state.dialogInstanceField.hide();
                },
                buttonModel: {
                  content: "CANCEL",
                  isSecondary: true,
                },
              },
            ]}
          >
            <div className="mb-2">
              {this.state.formVisible && (
                <GenericForm
                  data={
                    this.state.formFields.length > 0
                      ? this.state.formFields
                      : []
                  }
                  onChange={this.onChange}
                  datapostload={this.state.datapostload}
                  buttons={[]}
                />
              )}
            </div>
          </DialogComponent>
        )}
      </div>
    );
  }
}

export default CompDashboardDesinger;
